import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import BaseService from '../../helpers/baseServices';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Coupon } from '../../types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { RouteConstants } from '../../helpers/RouteConstants';
import Button from '../../components/Button';
import FilterModal from '../couponItems/FilterModal';
import ChooseDrawer from './chooseDrawer';
import TransferCouponModal from './transferCouponModal';
import { StorageBox } from '../../helpers/stroage';

import boxImg from '../../images/empty box.png';
import gasStation from '../../images/gas-station.png';

type FilterType = {
  f: string;
  o: string;
  p: any;
  c?: string;
};

type OwnershipTypes = 'ANOTHER' | 'SELF' | 'OTHERS';

const AllCoupons = () => {
  const [loading, setLoading] = useState(false);
  const [couponId, setCouponId] = useState<string>('');
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [openBottom, setOpenBottom] = useState(false);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);

  const [openTransfer, setOpenTransfer] = useState(false);

  const userInfo = StorageBox.retrieveUserData();
  const userId = userInfo?.user?.uid;

  //filters
  const [ownershipType, setOwnershipType] = useState<OwnershipTypes | null>(
    null
  );
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const navigate = useNavigate();

  const handleOpenTransfer = () => setOpenTransfer(true);
  const handleCloseTransfer = () => setOpenTransfer(false);

  const getAllCoupons = async (lim: number) => {
    const filters: FilterType[] = [];

    setLoading(true);

    if (ownershipType) {
      switch (ownershipType) {
        case 'SELF':
          filters.push({
            f: 'owner.id',
            o: '=',
            p: [`${userId}`],
          });
          break;
        case 'OTHERS':
          filters.push(
            {
              f: 'owner.id',
              o: '!=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '=',
              p: [`${userId}`],
            }
          );
          break;
        case 'ANOTHER':
          filters.push(
            {
              f: 'owner.id',
              o: '=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '!=',
              p: [`${userId}`],
            }
          );
          break;
        default:
      }
    }

    if (fromDate && toDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`,
        ],
      });
    }

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_COUPONS}?sorting=updated_at:desc&limit=${lim}&filters=${JSON.stringify(filters)}`
      );
      setCoupons(data?.payload?.items);
      setTotal(data?.payload?.total);
    } catch (e) {
      //handle error
    } finally {
      setLoading(false);
    }
  };

  //onLoadMore
  const onLoadMore = () => {
    if (limit < total) {
      setLimit(limit + 12);
    }
  };

  useEffect(() => {
    //console.log(userInfo?.user?.uid);
    getAllCoupons(limit);

    // eslint-disable-next-line
  }, [limit]);

  return (
    <Fragment>
      <div className="bg-neutral-50 relative h-screen overflow-y-auto">
        <div className="flex justify-between items-center mx-[20px] mt-5">
          <div className="flex-[0.1] ">
            <button
              className="h-10 text-neutral-300 flex justify-center items-center"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon className="h-6 w-6 font-bold" />
            </button>
          </div>
          <div className="flex-[0.8] flex items-center justify-center ">
            <h4 className="text-header-4">All coupons</h4>
          </div>
          <div className="flex-[0.1] flex items-end justify-end ">
            <button
              className="flex items-center gap-1"
              onClick={() => {
                setOpenFilter(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M3.59993 1.40039H12.3999C13.1333 1.40039 13.7333 2.00039 13.7333 2.73372V4.20039C13.7333 4.73372 13.3999 5.40039 13.0666 5.73372L10.1999 8.26706C9.79993 8.60039 9.53327 9.26706 9.53327 9.80039V12.6671C9.53327 13.0671 9.2666 13.6004 8.93327 13.8004L7.99994 14.4004C7.13327 14.9337 5.93327 14.3337 5.93327 13.2671V9.73372C5.93327 9.26706 5.6666 8.66706 5.39994 8.33372L2.8666 5.66706C2.53327 5.33372 2.2666 4.73372 2.2666 4.33372V2.80039C2.2666 2.00039 2.8666 1.40039 3.59993 1.40039Z"
                  stroke="#A53D00"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.28667 1.40039L4 6.66706"
                  stroke="#A53D00"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="btn-subtle">Filter</div>
            </button>
          </div>
        </div>

        <div className="mt-5 mx-[20px]">
          <>
            {loading ? (
              <div className="w-full animate-pulse">
                <div className="bg-gray-400 rounded-md mb-2 h-[100px] w-full" />
                <div className="bg-gray-400 rounded-md mb-2 h-[100px] w-full" />
                <div className="bg-gray-400 rounded-md mb-2 h-[100px] w-full" />
              </div>
            ) : (
              <>
                {coupons?.length > 0 ? (
                  <>
                    {coupons.map((item: Coupon, index: number) => (
                      <div key={index} className="mb-5">
                        <div className="coupon-card-container">
                          <div className="flex items-center justify-between">
                            <div
                              className={`${
                                item?.payment_status?.code === 'PENDING'
                                  ? 'bg-ks-yellow-100'
                                  : item?.payment_status?.code === 'COMPLETED'
                                    ? 'bg-ks-green-100'
                                    : 'bg-neutral-200'
                              } rounded-[8px] px-[8px] py-[4px] text-[12px] ${
                                item?.payment_status?.code === 'PENDING'
                                  ? 'text-ks-yellow-500'
                                  : item?.payment_status?.code === 'COMPLETED'
                                    ? 'text-ks-green-500'
                                    : 'bg-neutral-300'
                              }`}
                            >
                              {item?.payment_status?.name}
                            </div>
                            {item?.payment_status?.code === 'PENDING' ||
                            item?.payment_status?.code === 'USED' ? (
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M7.97333 5.27967C9.06318 5.27967 9.94667 4.39618 9.94667 3.30634C9.94667 2.2165 9.06318 1.33301 7.97333 1.33301C6.88349 1.33301 6 2.2165 6 3.30634C6 4.39618 6.88349 5.27967 7.97333 5.27967Z"
                                    fill="#939291"
                                  />
                                  <path
                                    d="M7.97333 9.89296C9.06318 9.89296 9.94667 9.00946 9.94667 7.91962C9.94667 6.82978 9.06318 5.94629 7.97333 5.94629C6.88349 5.94629 6 6.82978 6 7.91962C6 9.00946 6.88349 9.89296 7.97333 9.89296Z"
                                    fill="#939291"
                                  />
                                  <path
                                    d="M7.97333 14.5062C9.06318 14.5062 9.94667 13.6227 9.94667 12.5329C9.94667 11.4431 9.06318 10.5596 7.97333 10.5596C6.88349 10.5596 6 11.4431 6 12.5329C6 13.6227 6.88349 14.5062 7.97333 14.5062Z"
                                    fill="#939291"
                                  />
                                </svg>
                              </button>
                            ) : null}
                          </div>
                          <div className="my-4">
                            <div className="flex items-center gap-2">
                              <div className="w-[48px] h-[48px]">
                                {item?.company?.image_url ? (
                                  <img
                                    src={item?.company?.image_url}
                                    alt="company"
                                  />
                                ) : (
                                  <img src={gasStation} alt="" />
                                )}
                                <div className="text-primary font-semibold text-center overflow-ellipsis whitespace-nowrap overflow-hidden w-14">
                                  {item?.company?.name}
                                </div>
                              </div>
                              <div>
                                <div className="text-header-4">
                                  {item?.currency}
                                  {Intl.NumberFormat('en-EN').format(
                                    Number(item?.amount_left)
                                  )}
                                </div>
                                <div className="text-xs text-neutral-200">
                                  {item?.currency}
                                  {Intl.NumberFormat('en-EN').format(
                                    Number(item?.amount_used)
                                  )}{' '}
                                  used
                                </div>
                                <div className="text-xs text-neutral-200">
                                  Purchased on{' '}
                                  {moment(item?.created_at).format('Do MMM YY')}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end items-end w-full gap-[20px]">
                            <button
                              onClick={() => navigate(`/coupons/${item?.id}`)}
                              className="btn-small-outline"
                            >
                              View
                            </button>
                            {item?.payment_status?.code === 'COMPLETED' ? (
                              <>
                                {item?.owner_type?.code === 'SELF' && (
                                  <button
                                    className="btn-small-primary"
                                    onClick={() => {
                                      setCouponId(item?.id);
                                      handleOpenTransfer();
                                    }}
                                  >
                                    <div className="text-white flex items-center gap-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M7.06006 5.12012H9.88673V7.95345"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M9.88662 5.12012L6.11328 8.89345"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      <div>Transfer</div>
                                    </div>
                                  </button>
                                )}
                              </>
                            ) : item.payment_status?.code === 'PENDING' ? (
                              <button className="btn-small-primary">
                                <div className="text-white flex items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M5.99992 14.6663H9.99992C13.3333 14.6663 14.6666 13.333 14.6666 9.99967V5.99967C14.6666 2.66634 13.3333 1.33301 9.99992 1.33301H5.99992C2.66659 1.33301 1.33325 2.66634 1.33325 5.99967V9.99967C1.33325 13.333 2.66659 14.6663 5.99992 14.6663Z"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6 6.33984L8 4.33984L10 6.33984"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M8 4.33984V9.67318"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <div>Pay</div>
                                </div>
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center h-full">
                    <img
                      className="mt-10 lg:w-[300px]"
                      src={boxImg}
                      alt="empty"
                    />
                    <div className="text-center">
                      You have no coupons yet.{' '}
                      <button
                        onClick={() => setOpenBottom(true)}
                        className="text-[#5C8BFF] font-bold"
                      >
                        Click here
                      </button>{' '}
                      to buy coupon.
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>

        {limit >= total ? (
          <></>
        ) : (
          <div className="pb-14 flex justify-center items-center">
            <Button onClick={() => onLoadMore()} disabled={limit >= total}>
              <div className="flex items-center gap-2">
                View More
                <ChevronDoubleDownIcon className="h-4 w-4" />
              </div>
            </Button>
          </div>
        )}

        <br />
        <br />
        <br />
        <br />
      </div>
      <FilterModal
        isOpen={openFilter}
        handleOpenDrawer={() => setOpenFilter(false)}
        type="all"
        handleReset={() => {
          setOwnershipType(null);
          setFromDate(null);
          setToDate(null);
          setOpenFilter(false);
          getAllCoupons(12);
        }}
        handleOwnership={(value) => {
          setOwnershipType(value);
        }}
        handleFromDate={(date) => {
          setFromDate(date);
        }}
        handleToDate={(date) => setToDate(date)}
        applyFilter={(ownership, from, to) => {
          getAllCoupons(12);
          setOpenFilter(false);
        }}
      />
      {openBottom && (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => setOpenBottom(false)}
        />
      )}
      {openTransfer ? (
        <TransferCouponModal
          openModal={openTransfer}
          couponId={couponId}
          handleOpenModal={handleOpenTransfer}
          handleCloseModal={handleCloseTransfer}
        />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default AllCoupons;
