import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Option, Select, Spinner, Dialog } from "@material-tailwind/react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseService from "../../../../helpers/baseServices";
import { global_variables } from "../../../../helpers/globalVariables";
import PhoneInput, { Value } from "react-phone-number-input";
import { StorageBox } from "../../../../helpers/stroage";
import { toTwoDecimalPlaces } from "../../../../utils/funtions";
// import SuccessComponent from '../successComponent';
import ErrorComponent from "../errorComponent";
import CreatingCoupon from "../creatingCoupon";
import Button from "../../../../components/Button";
import ShowToast from "../../../../components/alerts/all_toasts";
import ProcessingTransaction from "../processingTransaction";
import ResultsPage from "../ResultsPage";
import { Coupon, PaymentInfo } from "../../../../types";
import { getTransactionStatus } from "../../../../utils/funtions";

const OtherPaymentOption = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [dataFromFirst, setDataFromFirst] = useState<any>(null);
  const [phoneNum, setPhoneNum] = useState<Value | undefined>("");
  const [accName, setAccName] = useState("");
  const [isError, setIsError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [allProviders, setAllProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [allWallets, setAllWallets] = useState([]);
  const [thePin, setThePin] = useState("");
  const [loggedUser, setLoggedUser] = useState<any>(null);

  const [couponData, setCouponData] = useState<Coupon | null>();
  const [paymentData, setPaymentData] = useState<PaymentInfo | null>();

  //processes
  const [isCreatingCoupon, setIsCreatingCoupon] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [endPool, setEndPool] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  //show success popup
  // const [showSuccessPop, setShowSuccessPop] = useState(false);
  // const [theTransId, setTheTransId] = useState<any>('');
  // const [theCoupId, setTheCoupId] = useState<any>('');
  // const handlePopShow = (trans: any, coup: any) => {
  //   setTheTransId(trans);
  //   setTheCoupId(coup);
  //   setShowSuccessPop(true);
  // };
  const handleClosePop = () => {
    //setShowSuccessPop(false);
    setThePin("");
    setIsBusy(false);
  };

  //show error popup
  const [showErrorPop, setShowErrorPop] = useState(false);
  const [errorData, setErrorData] = useState<any>("");
  const handleErrorShow = () => {
    setShowErrorPop(true);
  };
  const handleCloseError = () => {
    setShowErrorPop(false);
    setThePin("");
    setIsBusy(false);
  };

  useEffect(() => {
    const dataForPayment: any = localStorage.getItem("dataForOtherPayment");
    const ddd = JSON.parse(dataForPayment);
    // console.log(ddd)
    setDataFromFirst(ddd);
    fetchPaymentProviders();
    getWallets();

    const userData = StorageBox.retrieveUserData();
    setLoggedUser(userData);
  }, []);

  //get paymentProviders
  const fetchPaymentProviders = async () => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(
        global_variables.get_providers,
      );
      // console.log(response?.data)
      setAllProviders(response?.data?.payload?.items);
      setIsFetching(false);
    } catch (error) {
      // console.log(error)
    }
  };

  //get wallets
  const getWallets = () => {
    setIsFetching(true);
    BaseService.get_api(`/me/wallets`)
      .then((res) => {
        // console.log(res?.data?.payload)
        setAllWallets(res?.data?.payload?.items);
        setIsFetching(false);
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  //handle create coupon
  const handleCreateCoupon = async () => {
    if (!selectedProvider) {
      setIsError(true);
    } else if (selectedProvider !== "KOST_WALLET" && accName === "") {
      setIsError(true);
    } else if (selectedProvider === "KOST_WALLET" && thePin === "") {
      setIsError(true);
    } else {
      let formatPhoneNumber = phoneNum?.replace(/\s+/g, "");
      //handlePopShow(null, null);

      setIsBusy(true);
      let data = {
        account_name:
          selectedProvider === "KOST_WALLET" ? loggedUser?.user?.name : accName,
        account_number: formatPhoneNumber,
        pin: thePin,
        payment_provider: selectedProvider,
        ...dataFromFirst,
      };
      // console.log(data)
      try {
        setIsCreatingCoupon(true);
        const response = await BaseService.post_api(
          `${global_variables.create_coupon}`,
          data,
        );
        //console.log(response);
        setIsCreatingCoupon(true);
        if (response?.data?.payload?.payment === null) {
          setIsCreatingCoupon(false);
          handleClosePop();
          handleErrorShow();
          setErrorData(response?.data?.payload);
        } else {
          setPaymentData(response?.data?.payload?.payment);
          setCouponData(response?.data?.payload?.coupon);

          setIsCreatingCoupon(false);
          setIsProcessing(true);
          getTransactionStatus(response?.data?.payload?.payment?.id)
            .then((transRes: any) => {
              const statusCode = transRes?.data?.payload?.status?.code;
              if (statusCode === "SUCCESS") {
                setIsProcessing(false);
                ShowToast.success_toast("Success");

                setTimeout(() => {
                  //show coupon details
                  setIsSuccessful(true);
                  //setIsBusy(false);
                }, 800);
              } else if (statusCode === "FAILED") {
                setIsProcessing(false);
                ShowToast.error_toast("Failed");
                setTimeout(() => {
                  setIsFailed(true);
                }, 800);
              } else {
                setIsProcessing(false);
                setEndPool(true);
                //setIsBusy(false);
                console.log("pool end");
              }
            })
            .catch((e) => {
              //handle error
            });
        }

        // setTransactionPayload(response?.data?.payload);

        // if (response?.data?.payload?.payment === null) {
        //   console.log('Error');
        //   handleClosePop();
        //   handleErrorShow();
        //   setErrorData(response?.data?.payload);
        // } else {
        //   handlePopShow(
        //     response?.data?.payload?.payment?.id,
        //     response?.data?.payload?.coupon?.id
        //   );
        // }
      } catch (error: any) {
        // console.log(error)
        ShowToast.error_toast(error?.response?.data?.message || error?.message);
        setIsBusy(false);
      }
    }
  };

  return (
    <Fragment>
      {isBusy ? (
        <CreatingCoupon />
      ) : (
        <div className="h-screen overflow-hidden bg-neutral-50">
          {step === 1 ? (
            <div className="h-full mx-[20px] mt-5">
              <div className="flex justify-between items-center">
                <div className="flex-[0.3]">
                  <button
                    className="h-10 text-neutral-300 flex justify-center items-center"
                    onClick={() => navigate("/other-fuel")}
                  >
                    <ChevronLeftIcon className="h-6 w-6 font-bold" />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <h4 className="text-header-4">Payment method</h4>
                </div>
              </div>

              <div className="mt-10">
                <div>
                  <small
                    className={`${
                      isError
                        ? "text-red-500 ml-1 mb-2"
                        : "ml-1 mb-2 text-caption"
                    }`}
                  >
                    Payment method
                  </small>
                  <Select
                    label="Select payment method"
                    placeholder=""
                    className="bg-white h-[50px]"
                    onChange={(val: any) => {
                      setSelectedProvider(val);
                      setStep(2);
                    }}
                    disabled={isBusy || isFetching}
                    error={isError}
                  >
                    {allProviders.map((provider: any, i) => (
                      <Option key={i} value={provider?.code}>
                        {provider?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-full mx-[20px] mt-5">
              <div className="flex justify-between items-center">
                <div className="flex-[0.3]">
                  <button
                    className="h-10 text-neutral-300 flex justify-center items-center"
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    <ChevronLeftIcon className="h-6 w-6 font-bold" />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <h4 className="text-header-4">Payment method</h4>
                </div>
              </div>

              <div className="mt-10">
                <div>
                  <small
                    className={`${
                      isError
                        ? "text-red-500 ml-1 mb-2"
                        : "ml-1 mb-2 text-caption"
                    }`}
                  >
                    Payment method
                  </small>
                  <Select
                    label="Select payment method"
                    placeholder=""
                    className="bg-white h-[50px]"
                    onChange={(val: any) => {
                      setSelectedProvider(val);
                    }}
                    disabled={isBusy || isFetching}
                    error={isError}
                  >
                    {allProviders.map((provider: any, i) => (
                      <Option key={i} value={provider?.code}>
                        {provider?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div>
                {selectedProvider === "KOST_WALLET" ? (
                  <div className="mt-3">
                    <small
                      className={`${
                        isError
                          ? "text-red-500 ml-1 mb-2"
                          : "ml-1 mb-2 text-caption"
                      }`}
                    >
                      Choose wallet
                    </small>
                    <Select
                      label="Choose wallet"
                      placeholder=""
                      onChange={(acc) => setPhoneNum(acc)}
                      className="bg-white h-[50px]"
                    >
                      {allWallets.map((wallet: any, i: number) => (
                        <Option key={i} value={wallet?.number}>
                          <p>Wallet ID: {wallet?.number}</p>
                          <small>
                            Balance: {wallet?.currency}{" "}
                            {toTwoDecimalPlaces(wallet?.balance?.value)}
                          </small>
                        </Option>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <div className="mt-5">
                    <small
                      className={`${
                        isError
                          ? "text-red-500 ml-1 mb-2"
                          : "ml-1 mb-2 text-caption"
                      }`}
                    >
                      Enter mobile money number
                    </small>
                    <PhoneInput
                      className="input-container"
                      defaultCountry="GH"
                      inputStyle={{ width: "100%" }}
                      value={phoneNum}
                      onChange={(phone) => setPhoneNum(phone)}
                      numberInputProps={{
                        className: "focus:outline-none",
                      }}
                      disabled={isBusy}
                    />
                  </div>
                )}

                {selectedProvider === "KOST_WALLET" ? (
                  <div className="mt-3">
                    <small
                      className={`${
                        isError ? "text-red-500" : "ml-1 mb-2 text-caption"
                      }`}
                    >
                      Enter pin
                    </small>
                    <input
                      type="password"
                      className="input-container w-full"
                      placeholder="Enter pin"
                      value={thePin}
                      onChange={(e) => setThePin(e.target.value)}
                      disabled={isBusy}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {selectedProvider === "KOST_WALLET" ? (
                  <></>
                ) : (
                  <div className="mt-5">
                    <small className={`${isError ? "text-red-500" : ""}`}>
                      Enter account name
                    </small>
                    <input
                      className="input-container w-full"
                      placeholder="Enter name"
                      type="text"
                      value={
                        selectedProvider === "KOST_WALLET"
                          ? loggedUser?.user?.name
                          : accName
                      }
                      onChange={(e) => setAccName(e.target.value)}
                      disabled={isBusy || selectedProvider === "KOST_WALLET"}
                    />
                  </div>
                )}
              </div>

              <div className="mt-[50px] flex justify-center items-center">
                <Button
                  width="w-full"
                  onClick={() => handleCreateCoupon()}
                  disabled={isBusy}
                >
                  {isBusy ? <Spinner /> : <span>Buy Coupon</span>}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      <Dialog
        open={isBusy}
        handler={() => setIsBusy(false)}
        size="xxl"
        placeholder=""
      >
        {isCreatingCoupon && <CreatingCoupon />}
        {isProcessing && <ProcessingTransaction stillProcessing={false} />}
        {endPool && <ProcessingTransaction stillProcessing={true} />}
        {isSuccessful && (
          <ResultsPage
            type="SUCCESS"
            data={couponData}
            paymentInfo={paymentData}
          />
        )}
        {isFailed && (
          <ResultsPage
            type="FAILED"
            retry={() => {
              setIsBusy(false);
              setIsFailed(false);
            }}
            data={couponData}
            paymentInfo={paymentData}
          />
        )}
      </Dialog>

      {/* show success pop up */}
      {/* {showSuccessPop ? (
        <SuccessComponent
          openDrawer={showSuccessPop}
          handleCloseDrawer={handleClosePop}
          transactionId={theTransId}
          couponId={theCoupId}
          transactionPayload={transactionPayload}
        />
      ) : (
        <></>
      )} */}

      {/* show error pop up */}
      {showErrorPop ? (
        <ErrorComponent
          openErrorDrawer={showErrorPop}
          handleCloseErrorDrawer={handleCloseError}
          theError={errorData}
        />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default OtherPaymentOption;
