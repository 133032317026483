import { Fragment, useState, useEffect } from "react";
import { User } from "../../types";
import { RouteConstants } from "../../helpers/RouteConstants";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import PhoneInput from "react-phone-number-input";
import { Radio } from "@material-tailwind/react";
import ShowToast from "../../components/alerts/all_toasts";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { GetProp, UploadProps } from "antd";
import axios from "axios";
import ImgCrop from "antd-img-crop";
import BaseService from "../../helpers/baseServices";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const EditPersonal = ({
  handleStepChange,
}: {
  handleStepChange: () => void;
}) => {
  const [userPersonal, setPersonalData] = useState<User | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [middle_name, setMiddleName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUploadChange: UploadProps["onChange"] = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const getUserData = async () => {
    setIsLoading(true);
    try {
      const res = await BaseService.account_ms_get_request(`/v1/profile`);

      setPersonalData(res.data?.payload);
      setGender(res.data?.payload?.gender);
      setIsLoading(false);
      //return res;
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    // let formatIce1Phone = ice1_phone?.replace(/\s+/g, '');
    // let formatIce2Phone = ice2_phone?.replace(/\s+/g, '');

    setIsLoading(true);
    BaseService.put_api_auth(RouteConstants.PROFILE, {
      first_name: first_name ? first_name : userPersonal?.first_name,
      last_name: last_name ? last_name : userPersonal?.last_name,
      middle_name: middle_name ? middle_name : userPersonal?.middle_name,
      phone: userPersonal?.phone,
      gender: gender ? gender : userPersonal?.gender,
      email: email ? email : userPersonal?.email,
      ice1_phone: userPersonal?.ice1_phone,
      ice2_phone: userPersonal?.ice2_phone,
      birthdate: dob ? dob : userPersonal?.birthdate,
    })
      .then((response: any) => {
        // console.log(user_data);
        ShowToast.success_toast("Profile has been updated!");
        handleStepChange();
        window.location.reload();
      })
      .catch((e: any) => {
        ShowToast.error_toast(e?.response?.data?.message || e?.message);
      })
      .finally(() => setIsLoading(false));
  };
  const handleAction = async (file: any) => {
    try {
      const res = await BaseService.account_ms_post_request(
        `/v1/profile/image/presigned-upload`,
        {
          mime: file?.type,
        }
      );
      if (res.data?.payload?.upload_url) {
        await axios.put(res.data?.payload?.upload_url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
      }
      window.location.reload();
    } catch (error) {
      message.error("File upload failed. Try again");
    }
    return "";
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className="h-screen overflow-hidden bg-neutral-50">
        <div className="h-full mt-5">
          <div className="flex justify-between items-center mx-[20px]">
            <div className="flex-[0.3]">
              <button
                className="h-10 text-neutral-300 flex justify-center items-center"
                onClick={() => handleStepChange()}
              >
                <ChevronLeftIcon className="h-6 w-6 font-bold" />
              </button>
            </div>
            <div className="flex-[0.7]">
              <h4 className="text-header-4">Personal</h4>
            </div>
          </div>

          {isLoading ? (
            <div className="mt-5">
              <div className="animate-pulse">
                <div className="flex justify-center items-center">
                  <div className="w-[120px] h-[120px] rounded-[32px] bg-gray-300" />
                </div>
              </div>

              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-5 h-screen overflow-y-auto">
              <div className="flex justify-center items-center ">
                <div className="w-[120px] h-[120px]">
                  <ImgCrop rotationSlider>
                    <Upload
                      name="avatar"
                      listType="picture-circle"
                      className="avatar-uploader"
                      action={handleAction}
                      beforeUpload={beforeUpload}
                      onChange={handleUploadChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                  {/* {userPersonal?.image ? (
                    <img
                      src={userPersonal?.image}
                      className="bg-cover rounded-[32px]"
                      alt=""
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className=" w-24 h-24 sm:w-32 sm:h-32 rounded-full text-oya-ghana-green"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  )} */}
                </div>
              </div>
              <div className="border-t border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Name</div>
                    <div>
                      <div className="text-caption py-2 ml-1">First name</div>
                      <input
                        onChange={(e) => setFirstName(e.target.value)}
                        defaultValue={userPersonal?.first_name}
                        className="input-container w-full"
                      />
                    </div>
                    <div>
                      <div className="text-caption py-2 ml-1">Middle name</div>
                      <input
                        onChange={(e) => setMiddleName(e.target.value)}
                        defaultValue={userPersonal?.middle_name}
                        className="input-container w-full"
                      />
                    </div>
                    <div>
                      <div className="text-caption py-2 ml-1">Last name</div>
                      <input
                        onChange={(e) => setLastName(e.target.value)}
                        defaultValue={userPersonal?.last_name}
                        className="input-container w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Gender</div>
                    <div className="flex flex-col">
                      <Radio
                        name="gender"
                        defaultChecked={gender === "M" ? true : false}
                        placeholder=""
                        color="deep-orange"
                        label="Male"
                        value="M"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />

                      <Radio
                        name="gender"
                        defaultChecked={gender === "F" ? true : false}
                        placeholder=""
                        color="deep-orange"
                        label="Female"
                        value="F"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />

                      <Radio
                        name="gender"
                        placeholder=""
                        defaultChecked={gender === "O" ? true : false}
                        color="deep-orange"
                        label="Prefer not to say"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        value="O"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Date of birth</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Date of birth
                      </div>
                      <input
                        onChange={(e) => setDob(e.target.value)}
                        defaultValue={userPersonal?.birthdate}
                        className="input-container w-full"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Email address</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Email address
                      </div>
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={userPersonal?.email}
                        type="email"
                        className="input-container w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Number</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Mobile number
                      </div>
                      <div>
                        <PhoneInput
                          className="input-container"
                          defaultCountry="GH"
                          inputStyle={{ width: "100%" }}
                          value={userPersonal?.phone}
                          onChange={() => {}}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-evenly items-center mt-8">
                <button
                  className="text-neutral-300 font-medium"
                  onClick={() => handleStepChange()}
                >
                  Cancel
                </button>
                <button
                  className="font-semibold text-primary"
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EditPersonal;
