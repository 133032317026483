import React from "react";

type Props = {
  color: string;
  width?: string;
};

const Divider = ({ color, width = "full" }: Props) => {
  return <div className={`bg-${color} h-[1px] w-${width} `} />;
};

export default Divider;
