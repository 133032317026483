import { Drawer } from "@material-tailwind/react";
import failedImg from "../../../images/failed.gif";
import Button from "../../../components/Button";

interface drawerProps {
  openErrorDrawer: boolean;
  handleCloseErrorDrawer: () => void;
  theError: any;
}

const ErrorComponent = ({
  openErrorDrawer,
  handleCloseErrorDrawer,
  theError,
}: drawerProps) => {
  // const [failedData, setFailedData] = useState<any>("")
  const failedData = theError;
  console.log(theError);

  return (
    <>
      <Drawer
        placement="bottom"
        open={openErrorDrawer}
        placeholder=""
        className="p-4 rounded-t-[30px] shadow-xl"
        size={550}
      >
        <div className="mb-3 flex items-center justify-between">
          <h5>TRANSACTION STATUS</h5>
          {/* <IconButton
                        variant="text"
                        color="blue-gray"
                        onClick={handleCloseErrorDrawer}
                    >
                        <XMarkIcon className="h-5 w-5" />
                    </IconButton> */}
        </div>

        <hr />

        <div className="h-full">
          <div className="flex flex-col items-center mt-3">
            <div className="rounded-full flex justify-center items-center">
              <img src={failedImg} alt="fail" />
            </div>
            <h5 className="font-semibold">Payment Failed !</h5>
          </div>

          <hr className="my-3" />

          <div className="w-full text-center">
            {typeof failedData === "string" ? (
              <h5 className="font-normal">{failedData}</h5>
            ) : (
              <>
                <h5 className="font-normal">
                  {failedData?.response?.data?.message}
                </h5>

                <div className="flex justify-between items-center mt-3">
                  <h5 className="font-semibold">Amount: </h5>
                  {theError?.coupon ? (
                    <h5 className="font-normal">
                      {theError?.coupon?.currency}{" "}
                      {parseFloat(theError?.coupon?.price).toFixed(2)}
                    </h5>
                  ) : (
                    <h5 className="font-normal">--</h5>
                  )}
                </div>
                {/* 
                                    <div className="flex justify-between items-center mt-3">
                                        <h5 className="font-semibold">Payment Method: </h5>
                                        <h5 className="font-normal">{theError?.method?.name}</h5>
                                    </div> */}

                <div className="mt-3 flex justify-between">
                  <h5 className="font-semibold">Reference: </h5>
                  <h5 className="font-normal">
                    {theError?.coupon?.reference || "--"}
                  </h5>
                </div>
              </>
            )}

            <div className="mt-7">
              <Button width="w-full" onClick={() => handleCloseErrorDrawer()}>
                Retry
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ErrorComponent;
