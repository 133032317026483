import { Fragment, useEffect, useState } from "react";
import { Drawer, IconButton, Spinner } from "@material-tailwind/react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { StorageBox } from "../helpers/stroage";
import BaseService from "../helpers/baseServices";
import { toTwoDecimalPlaces } from "../utils/funtions";

interface drawerProps {
  openDrawer: boolean;
  handleCloseDrawer: () => void;
  selectedWallet: (data: any) => void;
}

const AllWalletsPopUp = ({
  openDrawer,
  handleCloseDrawer,
  selectedWallet,
}: drawerProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allWallets, setAllWallets] = useState([]);
  const [currentWalletId, setCurrentWalletId] = useState<any>("");

  useEffect(() => {
    const walletId = StorageBox.getWalletId();
    setCurrentWalletId(walletId);

    //get wallets
    const getWallets = () => {
      setIsFetching(true);
      BaseService.get_api(`/me/wallets`)
        .then((res) => {
          // console.log(res?.data?.payload)
          setAllWallets(res?.data?.payload?.items);
          setIsFetching(false);
        })
        .catch((err) => {
          // console.log(err)
        });
    };

    getWallets();
  }, []);

  //handleSelectWallet
  const handleSelectWallet = (data: any) => {
    // console.log(data)
    StorageBox.saveWalletId(data?.id);
    handleCloseDrawer();
    selectedWallet(data);
  };

  return (
    <Fragment>
      <Drawer
        placement="bottom"
        open={openDrawer}
        placeholder=""
        className="p-4 rounded-t-[30px] shadow-xl"
        size={450}
      >
        <div className="mb-3 flex items-center justify-between">
          <div className="text-xl font-semibold">MY WALLETS</div>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={handleCloseDrawer}
            placeholder=""
          >
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </div>

        <hr />

        {isFetching ? (
          <div className="flex justify-center h-full items-center">
            <Spinner />
          </div>
        ) : (
          <div className="h-full">
            {allWallets.length === 0 ? (
              <></>
            ) : (
              allWallets.map((wallet: any, i: number) => (
                <>
                  <div
                    key={i}
                    className="flex justify-between items-center my-3"
                  >
                    <input
                      type="checkbox"
                      className="h-5 w-5"
                      checked={currentWalletId === wallet?.id}
                      id="wal"
                      onChange={() => handleSelectWallet(wallet)}
                    />
                    <div>
                      <p>{wallet?.name}</p>
                      <p className="text-[12px] italic">
                        Wallet ID: {wallet?.number}
                      </p>
                    </div>
                    <p>
                      {wallet?.balance?.currency}{" "}
                      {toTwoDecimalPlaces(wallet?.balance?.value)}
                    </p>
                  </div>
                  <hr className="my-4" />
                </>
              ))
            )}
          </div>
        )}
      </Drawer>
    </Fragment>
  );
};

export default AllWalletsPopUp;
