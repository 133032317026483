import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary";
  width?: string;
}

const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  width,
  ...props
}) => {
  const solid = `${width} hover:transition hover:ease-in-out hover:delay-150 px-5 py-3 h-[55px] text-white rounded-md bg-primary hover:bg-primary/85 items-center justify-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`;
  const outline = `${width} hover:transition hover:ease-in-out hover:delay-150 h-[55px] px-5 py-3 font-bold border border-primary text-primary rounded-md hover:bg-primary hover:text-white items-center justify-center`;

  return (
    <button
      type="button"
      className={variant === "primary" ? solid : outline}
      {...props}
    />
  );
};

export default Button;
