import { Fragment, useState, useEffect } from 'react';
import Header from '../../components/Header';
import CustomNavigation from '../../components/CustomNavigation';
import Divider from '../../components/Divider';
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import gasStation from '../../images/gas-station.png';
import BaseService from '../../helpers/baseServices';
import { RouteConstants } from '../../helpers/RouteConstants';
import { Coupon } from '../../types';
import boxImg from '../../images/empty box.png';
import ChooseDrawer from './chooseDrawer';
import moment from 'moment';
// import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';

const CouponsPage = () => {
  const [pendingPayments, setPendingPayments] = useState<Coupon[]>([]);
  //   const [limit, setLimit] = useState(12);
  const [loading, setLoading] = useState(false);
  const [openBottom, setOpenBottom] = useState(false);
  const [stats, setStats] = useState<any>({});
  const navigate = useNavigate();

  //fetch stats
  const fetchStats = async () => {
    setLoading(true);
    try {
      const response = await BaseService.get_api(`${RouteConstants.ALL_STATS}`);
      // console.log(response)
      setStats(response.data?.payload);
      setLoading(false);
    } catch (error) {
      // console.log(error)
    }
  };

  const getPendingPayments = async (lim: number) => {
    setLoading(true);

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_PENDING_COUPONS}?sorting=updated_at:desc&limit=${lim}`
      );
      setPendingPayments(data?.payload?.items);
    } catch (e) {
      //handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPendingPayments(12);
    fetchStats();
  }, []);

  return (
    <Fragment>
      {openBottom && (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => setOpenBottom(false)}
        />
      )}
      <Header title="Coupons" showExtra={false} />
      <div className="bg-neutral-50 relative h-screen overflow-hidden">
        <div className="h-full w-full overflow-hidden">
          {/* main content */}
          <div>
            <div className="mt-5 h-full ">
              {/* stats cards */}
              {loading ? (
                <div className="grid grid-cols-2 gap-5 mx-[20px] ">
                  <div className="card-small-container animate-pulse ">
                    <div className="w-[50%] h-[50px] bg-gray-300" />
                  </div>
                  <div className="card-small-container animate-pulse">
                    <div className="w-[50%] h-[50px] bg-gray-300" />
                  </div>
                  <div className="card-small-container animate-pulse">
                    <div className="w-[50%] h-[50px] bg-gray-300" />
                  </div>
                  <div className="card-small-container animate-pulse">
                    <div className="w-[50%] h-[50px] bg-gray-300" />
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-5 mx-[20px]">
                  <button
                    onClick={() => navigate('/my-coupons')}
                    className="card-small-container"
                  >
                    <div className="text-[14px]">All coupons</div>
                    <div className="flex items-center gap-1">
                      <div className="colored-card-container bg-[#FFE0CC]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#FF9655"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                            stroke="#FF9655"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="text-left">
                        <div className="font-semibold">
                          GHS
                          {Intl.NumberFormat('en-EN').format(
                            stats?.total_amounts
                          )}
                        </div>
                        <div className="text-neutral-200 text-xs">
                          {stats?.total_coupons}
                        </div>
                      </div>
                    </div>
                  </button>
                  {/* pending payment */}
                  <button
                    className="card-small-container"
                    onClick={() => navigate('/all-coupons?q=pending')}
                  >
                    <div className="text-[14px]">Pending payments</div>
                    <div className="flex items-center gap-1">
                      <div className="colored-card-container bg-[#FFF7D6]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#CF9F02"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.94667 11.9739C8.94667 10.8841 8.06318 10.0006 6.97333 10.0006C5.88349 10.0006 5 10.8841 5 11.9739C5 13.0638 5.88349 13.9473 6.97333 13.9473C8.06318 13.9473 8.94667 13.0638 8.94667 11.9739Z"
                            fill="#CF9F02"
                          />
                          <path
                            d="M13.5599 11.9739C13.5599 10.8841 12.6765 10.0006 11.5866 10.0006C10.4968 10.0006 9.61328 10.8841 9.61328 11.9739C9.61328 13.0638 10.4968 13.9473 11.5866 13.9473C12.6765 13.9473 13.5599 13.0638 13.5599 11.9739Z"
                            fill="#CF9F02"
                          />
                          <path
                            d="M18.1732 11.9739C18.1732 10.8841 17.2897 10.0006 16.1999 10.0006C15.1101 10.0006 14.2266 10.8841 14.2266 11.9739C14.2266 13.0638 15.1101 13.9473 16.1999 13.9473C17.2897 13.9473 18.1732 13.0638 18.1732 11.9739Z"
                            fill="#CF9F02"
                          />
                        </svg>
                      </div>
                      <div className="text-left">
                        <div className="font-semibold">
                          GHS
                          {Intl.NumberFormat('en-US').format(
                            stats?.total_coupons_pending_payments_amount
                          )}
                        </div>
                        <div className="text-neutral-200 text-xs">
                          {stats?.total_coupons_pending_payments}
                        </div>
                      </div>
                    </div>
                  </button>
                  {/* paid coupons */}
                  <button
                    className="card-small-container"
                    onClick={() => navigate('/all-coupons?q=paid')}
                  >
                    <div className="text-[14px]">Paid coupons</div>
                    <div className="flex items-center gap-1">
                      <div className="colored-card-container bg-[#DCFFF1]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#2ABB7F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6 11.9534L9.95342 15.9068L17.84 8"
                            stroke="#2ABB7F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="text-left">
                        <div className="font-semibold">
                          GHS
                          {Intl.NumberFormat('en-EN').format(
                            stats?.total_amount_paid
                          )}
                        </div>
                        <div className="text-neutral-200 text-xs">
                          {stats?.total_coupons_paid}
                        </div>
                      </div>
                    </div>
                  </button>
                  {/* used coupons */}
                  <button
                    className="card-small-container"
                    onClick={() => navigate('/all-coupons?q=used')}
                  >
                    <div className="text-[14px]">Used coupons</div>
                    <div className="flex gap-1">
                      <div className="colored-card-container bg-[#D4D3D3]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#484442"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 12L17 12"
                            stroke="#484442"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="text-left">
                        <div className="font-semibold">
                          GHS
                          {Intl.NumberFormat('en-EN').format(
                            stats?.total_coupons_not_used_amount
                          )}
                        </div>
                        <div className="text-neutral-200 text-xs">
                          {stats?.total_coupons_not_used}
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              )}

              <div className="my-[24px]">
                <Divider color="neutral-100" />
              </div>
              {/* list */}
              <div className="h-screen overflow-y-auto">
                <div className="mx-[20px] ">
                  <div className="flex items-center justify-between">
                    <div>Pending payments</div>
                    <button
                      onClick={() => navigate('/all-coupons?q=pending')}
                      className="btn-subtle flex gap-2 items-center"
                    >
                      <div>See all</div>
                      <ChevronRightIcon className="w-5 h-5" />
                    </button>
                  </div>
                  {loading ? (
                    <>
                      {['', '', '', '', ''].map((dd, i) => (
                        <div
                          key={i}
                          className="w-full bg-white shadow-lg p-5 rounded-[10px] flex justify-between mb-3 animate-pulse"
                        >
                          <div>
                            <p className="font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg">
                              GHS 100.00
                            </p>
                            <p className="text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg">
                              Self
                            </p>
                          </div>

                          <div>
                            <p className="text-xs font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg">
                              Do MMM YYYY (hh:mm A)
                            </p>
                            <p className="text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg">
                              Not Paid
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="mt-5">
                      {pendingPayments?.length > 0 ? (
                        <div className="coupon-card-container">
                          <div className="flex items-center justify-between">
                            <div className="bg-[#FFF7D6] rounded-[8px] px-[8px] py-[4px] text-[12px] text-[#CF9F02]">
                              Pending Payment
                            </div>
                            <button>
                              <EllipsisVerticalIcon className="w-6 h-6 text-neutral-300" />
                            </button>
                          </div>
                          <div className="my-4">
                            <div className="flex items-center gap-2">
                              <div className="w-[48px] h-[48px]">
                                {pendingPayments[0]?.company?.image_url ? (
                                  <img
                                    src={pendingPayments[0]?.company?.image_url}
                                    alt="company"
                                  />
                                ) : (
                                  <img src={gasStation} alt="" />
                                )}
                                <div className="text-primary font-semibold text-center">
                                  {pendingPayments[0]?.company?.name}
                                </div>
                              </div>
                              <div>
                                <div className="text-header-4">
                                  {pendingPayments[0]?.currency}
                                  {Intl.NumberFormat('en-EN').format(
                                    Number(pendingPayments[0]?.amount_left)
                                  )}
                                </div>
                                <div className="text-xs text-neutral-200">
                                  {pendingPayments[0]?.currency}
                                  {Intl.NumberFormat('en-EN').format(
                                    Number(pendingPayments[0]?.amount_used)
                                  )}{' '}
                                  used
                                </div>
                                <div className="text-xs text-neutral-200">
                                  Purchased on{' '}
                                  {moment(
                                    pendingPayments[0]?.created_at
                                  ).format('Do MMM YY')}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end items-end w-full gap-[20px]">
                            <button
                              onClick={() =>
                                navigate(`/coupons/${pendingPayments[0]?.id}`)
                              }
                              className="btn-small-outline"
                            >
                              View
                            </button>
                            <button
                              onClick={() => {
                                if (
                                  pendingPayments[0]?.owner_type?.code ===
                                  'SELF'
                                ) {
                                  let dataForPayment = JSON.stringify({
                                    price: pendingPayments[0]?.price
                                      .toFixed(2)
                                      .toString(),
                                    type: 'SELF',
                                    fuel_type_code:
                                      pendingPayments[0]?.fuel_type?.code,
                                    company_code:
                                      pendingPayments[0]?.company?.code,
                                  });
                                  localStorage.setItem(
                                    'dataForPayment',
                                    dataForPayment
                                  );

                                  navigate('/retry-payment');
                                } else if (
                                  pendingPayments[0]?.owner_type?.code ===
                                  'ANOTHER'
                                ) {
                                  let dataForPayment = JSON.stringify({
                                    price: pendingPayments[0]?.price
                                      .toFixed(2)
                                      .toString(),
                                    type: 'ANOTHER',
                                    fuel_type_code:
                                      pendingPayments[0]?.fuel_type?.code,
                                    company_code:
                                      pendingPayments[0]?.company?.code,
                                    phone: pendingPayments[0]?.owner?.phone,
                                    first_name:
                                      pendingPayments[0]?.owner?.first_name,
                                    last_name:
                                      pendingPayments[0]?.owner?.last_name,
                                  });
                                  // console.log(JSON.parse(dataForPayment))
                                  localStorage.setItem(
                                    'dataForOtherPayment',
                                    dataForPayment
                                  );

                                  navigate('/retry-payment-others');
                                }
                              }}
                              className="btn-small-primary"
                            >
                              <div className="text-white flex items-center gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M5.99992 14.6663H9.99992C13.3333 14.6663 14.6666 13.333 14.6666 9.99967V5.99967C14.6666 2.66634 13.3333 1.33301 9.99992 1.33301H5.99992C2.66659 1.33301 1.33325 2.66634 1.33325 5.99967V9.99967C1.33325 13.333 2.66659 14.6663 5.99992 14.6663Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 6.33984L8 4.33984L10 6.33984"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8 4.33984V9.67318"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                <div>Pay</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col justify-center items-center h-full">
                          <img
                            className="mt-10 lg:w-[300px]"
                            src={boxImg}
                            alt="empty"
                          />
                          <div className="text-center">
                            You have no coupons yet.{' '}
                            <button
                              onClick={() => setOpenBottom(true)}
                              className="text-[#5C8BFF] font-bold"
                            >
                              Click here
                            </button>{' '}
                            to buy coupon.
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* foot */}
          <div className="fixed w-full bottom-0 bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800">
            <CustomNavigation activeIndex={1} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CouponsPage;
