import { Fragment, useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  Select,
  Option,
  Spinner,
} from "@material-tailwind/react";

import { StorageBox } from "../helpers/stroage";
import BaseService from "../helpers/baseServices";
import { global_variables } from "../helpers/globalVariables";
import PhoneInput, { Value } from "react-phone-number-input";
import { getTransactionStatus, toTwoDecimalPlaces } from "../utils/funtions";
import PinInput from "react-pin-input";
import verifyImg from "../images/verify.gif";
import processingImg from "../images/processing.gif";
import processingGif from "../images/processingGif.gif";
import Button from "./Button";

interface drawerProps {
  openDrawer: boolean;
  handleCloseDrawer: () => void;
  handleReFetch: () => void;
}

const TopUpWalletModal = ({
  openDrawer,
  handleCloseDrawer,
  handleReFetch,
}: drawerProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allProviders, setAllProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [isError, setIsError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [phoneNum, setPhoneNum] = useState<Value | undefined>("");
  const [accName, setAccName] = useState("");
  const [theWalletId, setTheWalletId] = useState<any>(null);
  const [theAmount, setTheAmount] = useState("");
  const [thePin, setThePin] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [transactionData, setTransactionData] = useState<any>(null);
  const [isFailed, setIsFailed] = useState(false);
  const [failedData, setFailedData] = useState<any>("");
  const [allWallets, setAllWallets] = useState([]);
  const [loggedUser, setLoggedUser] = useState<any>(null);
  const [isEnterOtp, setIsEnterOtp] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [endPool, setEndPool] = useState(false);

  useEffect(() => {
    const walletId = StorageBox.getWalletId();
    setTheWalletId(walletId);
    getWallets();

    const userData = StorageBox.retrieveUserData();
    setLoggedUser(userData);

    fetchPaymentProviders();
  }, []);

  //get paymentProviders
  const fetchPaymentProviders = async () => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(
        global_variables.get_providers,
      );
      // console.log(response?.data)
      setAllProviders(response?.data?.payload?.items);
      setIsFetching(false);
    } catch (error) {
      // console.log(error)
    }
  };

  //get wallets
  const getWallets = () => {
    setIsFetching(true);
    BaseService.get_api(`/me/wallets`)
      .then((res) => {
        // console.log(res?.data?.payload)
        setAllWallets(res?.data?.payload?.items);
        setIsFetching(false);
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  //runOtpVerification
  const runOtpVerification = (theOtp: any) => {
    setIsVerifying(true);
    console.log(typeof transactionId);
    BaseService.post_api(`/transactions/${transactionId}/authorize`, {
      credential: theOtp,
    })
      .then((response) => {
        console.log(response?.data);
        //verify transaction status
        setIsVerifying(false);
        setIsBusy(true);
        getTransactionStatus(transactionId)
          .then((transRes: any) => {
            console.log(transRes);
            const statusCode = transRes?.data?.payload?.status?.code;
            if (statusCode === "SUCCESS") {
              setTransactionData(response?.data?.payload);
              setIsSuccessful(true);
              setIsBusy(false);
              setIsVerifying(false);
            } else if (statusCode === "FAILED") {
              setFailedData(response?.data?.payload);
              setIsFailed(true);
              setIsBusy(false);
              setIsVerifying(false);
            } else {
              setEndPool(true);
              setIsBusy(false);
              setIsVerifying(false);
              console.log("pool end");
            }
          })
          .catch((error) => {
            console.log(error);
            setIsFailed(true);
            setFailedData(error?.response?.data?.message);
            setIsBusy(false);
            setIsVerifying(false);
          });
      })
      .catch((error) => {
        //failed otp verification
        setIsVerifying(false);
        console.log(error);
        setIsFailed(true);
        setFailedData(error?.response?.data?.message);
      });
  };

  //performTopUp
  const performTopUp = () => {
    if (
      !accName ||
      !phoneNum ||
      !selectedProvider ||
      !theAmount ||
      (selectedProvider === "KOST_WALLET" && thePin === "")
    ) {
      setIsError(true);
    } else {
      let formatPhoneNumber = phoneNum?.replace(/\s+/g, "");
      let data = {
        amount: parseFloat(theAmount).toFixed(2),
        source: {
          account_name:
            selectedProvider === "KOST_WALLET"
              ? loggedUser?.user?.name
              : accName,
          account_number: formatPhoneNumber,
          account_provider: selectedProvider,
          auth: {
            pin: thePin,
          },
        },
      };
      setIsBusy(true);

      BaseService.post_api(
        `${global_variables.wallets_route}/${theWalletId}/fund`,
        data,
      )
        .then((response) => {
          //console.log(response);
          setTransactionId(response?.data?.payload?.id);
          if (
            response?.data?.payload.hasOwnProperty("auth") &&
            response?.data?.payload?.auth?.type === "OTP"
          ) {
            setIsEnterOtp(true);
            setIsBusy(false);
          } else {
            console.log("getting transaction status...");
            getTransactionStatus(response?.data?.payload?.id)
              .then((transRes: any) => {
                console.log(transRes);
                const statusCode = transRes?.data?.payload?.status?.code;
                if (statusCode === "SUCCESS") {
                  setTransactionData(transRes?.data?.payload);
                  setIsSuccessful(true);
                  setIsBusy(false);
                } else if (statusCode === "FAILED") {
                  setFailedData(transRes?.data?.payload);
                  setIsFailed(true);
                  setIsBusy(false);
                } else {
                  setEndPool(true);
                  setIsBusy(false);
                  console.log("pool end");
                }
              })
              .catch((error) => {
                console.log(error);
                setIsFailed(true);
                setFailedData(error?.response?.data?.message);
                setIsBusy(false);
              });
          }
        })
        .catch((error: any) => {
          console.log(error);
          setIsFailed(true);
          setFailedData(error?.response?.data?.message);
          setIsBusy(false);
        });
    }
  };

  //handleRetry
  const handleRetry = () => {
    setIsBusy(false);
    setSelectedProvider("");
    setIsFailed(false);
    setIsEnterOtp(false);
  };

  //handleDismis
  const handleDismiss = () => {
    window.location.href = "/home";
  };

  return (
    <Fragment>
      <Drawer
        placement="bottom"
        open={openDrawer}
        placeholder=""
        className="p-4 rounded-t-[30px] shadow-xl bg-neutral-50"
        size={750}
      >
        <div className="mb-3 flex items-center justify-between">
          <div className="text-header-4">Wallet Top up</div>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={handleCloseDrawer}
            placeholder=""
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M10.3519 21.9445C10.0083 21.9445 9.66463 21.8179 9.39335 21.5466C8.86888 21.0222 8.86888 20.1541 9.39335 19.6296L19.6296 9.39335C20.1541 8.86888 21.0222 8.86888 21.5466 9.39335C22.0711 9.91783 22.0711 10.7859 21.5466 11.3104L11.3104 21.5466C11.0572 21.8179 10.6955 21.9445 10.3519 21.9445Z"
                fill="#484442"
              />
              <path
                d="M20.5881 21.9445C20.2445 21.9445 19.9009 21.8179 19.6296 21.5466L9.39335 11.3104C8.86888 10.7859 8.86888 9.91783 9.39335 9.39335C9.91783 8.86888 10.7859 8.86888 11.3104 9.39335L21.5466 19.6296C22.0711 20.1541 22.0711 21.0222 21.5466 21.5466C21.2754 21.8179 20.9317 21.9445 20.5881 21.9445Z"
                fill="#484442"
              />
            </svg>
          </IconButton>
        </div>

        <hr />

        {isBusy ? (
          <div className="h-full">
            <div className="flex flex-col items-center mt-3 justify-center h-full">
              <img className="w-[102px] h-[110px]" src={processingGif} alt="" />

              <div className="mt-4">
                <div className="font-medium mt-3">
                  Payment is being processed. Please wait.
                </div>
              </div>
            </div>
          </div>
        ) : isEnterOtp ? (
          <div className="h-full">
            <div className="flex justify-center mt-4">
              <img src={verifyImg} alt="verify" />
            </div>

            <p className="text-center mt-3">
              Enter the verification code we sent to your phone number
            </p>

            <div className="mt-5 flex justify-center">
              <PinInput
                length={6}
                initialValue=""
                secret
                secretDelay={100}
                onChange={(value, index) => {}}
                type="custom"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{ borderColor: "red" }}
                inputFocusStyle={{ borderColor: "green" }}
                onComplete={(value, index) => runOtpVerification(value)}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                disabled={isVerifying}
              />
            </div>

            <div className="mt-5 flex justify-center">
              {isVerifying ? (
                <Spinner color="green" className="h-8 w-8" />
              ) : (
                <>
                  {isFailed ? (
                    <div>
                      <div className="flex justify-center items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M21.5375 17.5315C22.3542 18.2485 22.3542 19.4352 21.5375 20.1522C21.115 20.5231 20.58 20.6962 20.0449 20.6962C19.5098 20.6962 18.9747 20.5231 18.5523 20.1522L12.075 14.4657L5.59771 20.1522C5.17528 20.5231 4.6402 20.6962 4.10512 20.6962C3.57004 20.6962 3.03496 20.5231 2.61253 20.1522C1.79582 19.4352 1.79582 18.2485 2.61253 17.5315L9.08981 11.845L2.61253 6.15848C1.79582 5.44149 1.79582 4.25474 2.61253 3.53775C3.42923 2.82075 4.78101 2.82075 5.59771 3.53775L12.075 9.22425L18.5523 3.53775C19.369 2.82075 20.7208 2.82075 21.5375 3.53775C22.3542 4.25474 22.3542 5.44149 21.5375 6.15848L15.0602 11.845L21.5375 17.5315Z"
                            fill="#F15B50"
                          />
                        </svg>
                        <div className="text-[#F15B50] text-[24px]">Failed</div>
                      </div>
                      {/* <div className='text-header-4'>{failedData}</div> */}
                      <Button width="w-full" onClick={() => handleRetry()}>
                        Retry
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        ) : isSuccessful ? (
          <div className="h-full">
            <div className="flex gap-2 items-center justify-center mt-5 mb-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
              >
                <path
                  d="M2 12.2925L9.29247 19.5849L23.84 5"
                  stroke="#2ABB7F"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="font-semibold text-ks-green-500">Succeess</div>
            </div>

            <div className="w-full">
              <div className="flex justify-between items-center">
                <div className="font-semibold">Amount: </div>
                <div className="font-normal">
                  {transactionData?.amount?.currency}{" "}
                  {toTwoDecimalPlaces(transactionData?.amount?.value)}
                </div>
              </div>

              <div className="flex justify-between items-center mt-3">
                <div className="font-semibold">Payment Method: </div>
                <div className="font-normal">
                  {transactionData?.method?.name}
                </div>
              </div>

              <div className="mt-3">
                <div className="font-semibold">Reference: </div>
                <div className="font-normal">{transactionData?.reference}</div>
              </div>
            </div>
          </div>
        ) : isFailed ? (
          <div className="h-full">
            <div className="flex flex-col items-center mt-3">
              <div className="flex justify-center items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.5375 17.5315C22.3542 18.2485 22.3542 19.4352 21.5375 20.1522C21.115 20.5231 20.58 20.6962 20.0449 20.6962C19.5098 20.6962 18.9747 20.5231 18.5523 20.1522L12.075 14.4657L5.59771 20.1522C5.17528 20.5231 4.6402 20.6962 4.10512 20.6962C3.57004 20.6962 3.03496 20.5231 2.61253 20.1522C1.79582 19.4352 1.79582 18.2485 2.61253 17.5315L9.08981 11.845L2.61253 6.15848C1.79582 5.44149 1.79582 4.25474 2.61253 3.53775C3.42923 2.82075 4.78101 2.82075 5.59771 3.53775L12.075 9.22425L18.5523 3.53775C19.369 2.82075 20.7208 2.82075 21.5375 3.53775C22.3542 4.25474 22.3542 5.44149 21.5375 6.15848L15.0602 11.845L21.5375 17.5315Z"
                    fill="#F15B50"
                  />
                </svg>
                <div className="text-[#F15B50] text-[24px]">Failed</div>
              </div>
              {/* <div className='text-header-4'>{failedData}</div> */}
            </div>

            <hr className="my-3" />

            <div className="w-full text-center">
              {typeof failedData === "string" ? (
                <div className="font-normal">Failed transaction</div>
              ) : (
                <>
                  <div className="flex justify-between items-center">
                    <div className="font-semibold">Amount: </div>
                    <div className="font-normal">
                      {failedData?.amount?.currency}{" "}
                      {toTwoDecimalPlaces(failedData?.amount?.value)}
                    </div>
                  </div>

                  <div className="flex justify-between items-center mt-3">
                    <div className="font-semibold">Payment Method: </div>
                    <div className="font-normal">
                      {failedData?.method?.name}
                    </div>
                  </div>

                  <div className="flex justify-between items-center mt-3 mb-16">
                    <div className="font-semibold">Reference: </div>
                    <div className="font-normal">{failedData?.reference}</div>
                  </div>
                </>
              )}

              <Button width="w-full" onClick={() => handleRetry()}>
                Retry
              </Button>
            </div>
          </div>
        ) : endPool ? (
          <div className="h-full">
            <div className="flex flex-col items-center justify-center h-full">
              <div className="rounded-full flex justify-center items-center mb-4">
                <img className="" src={processingImg} alt="success" />
              </div>
              <div className="font-normal mt-4 text-center mb-16">
                Hello payment is still processing. This occurs when transaction
                is aborted. Once transaction is successfully processed it will
                reflect in your wallet
              </div>
              <Button width="w-full" onClick={() => handleDismiss()}>
                Dismiss
              </Button>
            </div>
          </div>
        ) : (
          <div className="h-full mt-8">
            <div className="mt-5">
              <small
                className={`${
                  isError ? "text-red-500 ml-1 mb-2" : "ml-1 mb-2 text-captio"
                }`}
              >
                Amount
              </small>
              <input
                className="input-container w-full"
                placeholder="Amount"
                type="number"
                value={theAmount}
                onChange={(e) => {
                  const newValue: any = e.target.value;
                  if (newValue >= 0) {
                    setTheAmount(newValue);
                  }
                }}
              />
            </div>

            <div className="mt-5">
              <small
                className={`${
                  isError ? "text-red-500 ml-1 mb-2" : "ml-1 mb-2 text-caption"
                }`}
              >
                Your payment provider
              </small>
              <Select
                className="h-[50px] bg-white"
                placeholder="Select provider"
                onChange={(val: any) => setSelectedProvider(val)}
                disabled={isBusy || isFetching}
              >
                {allProviders
                  ?.filter((provider: any) => provider.code !== "KOST_WALLET")
                  .map((provider: any, i) => (
                    <Option key={i} value={provider?.code}>
                      {provider?.name}
                    </Option>
                  ))}
              </Select>
            </div>

            {selectedProvider === "KOST_WALLET" ? (
              <div className="mt-5">
                <small
                  className={`${
                    isError
                      ? "text-red-500 ml-1 mb-2"
                      : "ml-1 mb-2 text-caption"
                  }`}
                >
                  Choose Wallet
                </small>
                <Select
                  placeholder="Select provider"
                  onChange={(acc: any) => setPhoneNum(acc)}
                >
                  {allWallets.map((wallet: any, i: number) => (
                    <Option key={i} value={wallet?.number}>
                      <p>Wallet ID: {wallet?.number}</p>
                      <small>
                        Balance: {wallet?.currency}{" "}
                        {toTwoDecimalPlaces(wallet?.balance?.value)}
                      </small>
                    </Option>
                  ))}
                </Select>
              </div>
            ) : (
              <div className="mt-5">
                <small
                  className={`${
                    isError
                      ? "text-red-500 ml-1 mb-2"
                      : "ml-1 mb-2 text-caption"
                  }`}
                >
                  Your payment number
                </small>
                <PhoneInput
                  className="input-container"
                  defaultCountry="GH"
                  inputStyle={{ width: "100%" }}
                  value={phoneNum}
                  onChange={(phone) => setPhoneNum(phone)}
                  disabled={isBusy}
                  numberInputProps={{
                    className: "focus:outline-none",
                  }}
                />
              </div>
            )}

            {selectedProvider === "KOST_WALLET" ? (
              <div className="mt-5">
                <small
                  className={`${
                    isError
                      ? "text-red-500 ml-1 mb-2"
                      : "ml-1 mb-2 text-caption"
                  }`}
                >
                  Your pin
                </small>
                <input
                  type="password"
                  className="input-container w-full"
                  placeholder="Enter pin"
                  value={thePin}
                  onChange={(e) => setThePin(e.target.value)}
                  disabled={isBusy}
                />
              </div>
            ) : (
              <></>
            )}

            {selectedProvider === "KOST_WALLET" ? (
              <></>
            ) : (
              <div className="mt-5">
                <small
                  className={`${
                    isError
                      ? "text-red-500 ml-1 mb-2"
                      : "ml-1 mb-2 text-caption"
                  }`}
                >
                  Your payment name
                </small>
                <input
                  className="input-container w-full"
                  placeholder="Enter payment name"
                  type="text"
                  value={
                    selectedProvider === "KOST_WALLET"
                      ? loggedUser?.user?.name
                      : accName
                  }
                  onChange={(e) => setAccName(e.target.value)}
                  disabled={isBusy || selectedProvider === "KOST_WALLET"}
                />
              </div>
            )}

            <div className="mt-16">
              <Button
                width="w-full"
                onClick={() => performTopUp()}
                disabled={isBusy}
              >
                {isBusy ? <Spinner /> : "Top Up Now"}
              </Button>
            </div>
          </div>
        )}
      </Drawer>
    </Fragment>
  );
};

export default TopUpWalletModal;
