import {
  ChevronDoubleDownIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { StorageBox } from "../../helpers/stroage";
import moment from "moment";
import { toTwoDecimalPlaces } from "../../utils/funtions";
import { RouteConstants } from "../../helpers/RouteConstants";
import BaseService from "../../helpers/baseServices";

const Transactions = () => {
  const navigate = useNavigate();
  const [allTransactions, setAllTransactions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);

  const [openDet, setOpenDet] = useState(0);

  const handleOpen = (value: any) => setOpenDet(openDet === value ? 0 : value);

  //on mount
  useEffect(() => {
    const theWalletId = StorageBox.getWalletId();

    getTransactions(theWalletId, limit);
  }, [limit]);

  //get all transactions
  const getTransactions = async (walledId: any, lim: number) => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(
        `${RouteConstants.WALLET_ROUTE}/${walledId}/transactions?sorting=created_at:desc&limit=${lim}`
      );
      // console.log(response?.data?.payload)
      setAllTransactions(response?.data?.payload?.items);
      setTotal(response?.data?.payload?.total);
      setIsFetching(false);
    } catch (error) {
      // console.log(error)
    }
  };

  //onLoadMore
  const onLoadMore = () => {
    if (limit < total) {
      setLimit(limit + 12);
    }
  };

  return (
    <>
      <div className="h-screen overflow-hidden bg-[#d7f5f555] relative">
        <div className="h-full w-full ">
          <div className="flex justify-between items-center px-5 py-3">
            <button
              className="h-10 w-10 flex justify-center items-center bg-gray-400 rounded-full"
              onClick={() => navigate("/home")}
            >
              <ChevronLeftIcon className="h-5 w-5 text-white" />
            </button>
            <div>Transactions</div>
            <div></div>
          </div>

          <hr className="mt-2" />

          {/* content */}
          <div className="h-full overflow-y-auto pb-20 pl-5">
            {isFetching ? (
              ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
                (dd, i) => (
                  <div
                    key={i}
                    className="p-3 mb-3 border-b border-b-gray-300 bg-gray-300 flex justify-between animate-pulse"
                  >
                    <div className="flex gap-3">
                      <div className="px-4 py-1  text-transparent w-fit h-fit rounded-[5px] text-sm">
                        Credit
                      </div>
                      <p className="font-semibold text-transparent w-fit h-[10px] mt-2 rounded-full">
                        GHS 0.10
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-transparent w-fit h-[10px] rounded-full">
                        Wed, 8/30/2023
                      </p>
                      <p className="text-transparent w-fit h-[10px] mt-3 rounded-full text-sm">
                        Successful
                      </p>
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="pr-5">
                {allTransactions.map((transaction: any, i: number) => (
                  <>
                    <Accordion
                      placeholder=""
                      open={openDet === i}
                      className="border-b border-b-gray-300"
                    >
                      <AccordionHeader
                        placeholder=""
                        className="border-none mb-0"
                        key={i}
                        onClick={() => handleOpen(i)}
                      >
                        <div className="px-3 flex justify-between mb-0 w-full">
                          <div className="flex gap-3">
                            {transaction?.type?.code === "CREDIT" ? (
                              <div className="px-4 py-1 bg-green-500 text-white w-fit h-fit rounded-[5px] text-sm">
                                {transaction?.type?.name}
                              </div>
                            ) : (
                              <div className="px-4 py-1 bg-red-500 text-white w-fit h-fit rounded-[5px] text-sm">
                                {transaction?.type?.name}
                              </div>
                            )}
                            <p className="font-semibold">
                              {transaction?.amount?.currency}{" "}
                              {toTwoDecimalPlaces(transaction?.amount?.value)}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm">
                              {moment(transaction?.created_at).format(
                                "ddd, M/D/YYYY"
                              )}
                            </p>
                            {transaction?.status?.code === "SUCCESS" ? (
                              <p className="text-green-500 text-sm">
                                {transaction?.status?.name}
                              </p>
                            ) : (
                              <p className="text-red-500 text-sm">
                                {transaction?.status?.name}
                              </p>
                            )}
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody className="mt-0">
                        <div className="px-3">
                          <p>
                            <span className="font-semibold">Description: </span>
                            {transaction?.description}
                          </p>
                          <p>
                            <span className="font-semibold mt-1">
                              Reference:{" "}
                            </span>
                            {transaction?.reference}
                          </p>
                        </div>
                      </AccordionBody>
                    </Accordion>
                  </>
                ))}

                {limit >= total ? (
                  <></>
                ) : (
                  <div className="flex justify-center items-center">
                    <button
                      className="px-3 py-1 rounded-[5px] bg-orange-500 text-white flex justify-center items-center gap-2 disabled:bg-gray-400 disabled:cursor-not-allowed"
                      onClick={() => onLoadMore()}
                      disabled={limit >= total}
                    >
                      View More
                      <ChevronDoubleDownIcon className="h-4 w-4" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
