import { Navigate } from 'react-router-dom'
import { StorageBox } from '../helpers/stroage'

type RedirectToHomeRouteProps = {
  children: React.ReactNode | any
}

const RedirectToHomeRoute = ({ children }: RedirectToHomeRouteProps) => {
  const token = StorageBox.getAccessToken()

  if (token) {
    return <Navigate to='/home' />
  } else {
    return children
  }
}

export default RedirectToHomeRoute
