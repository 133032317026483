import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { BsWhatsapp, BsFacebook, BsEnvelopeAtFill } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";

const ContactUs = () => {
  const navigate = useNavigate();

  //handleFacebook
  const handleFacebook = () => {
    window.open("https://web.facebook.com/oyaghanaofficial", "_blank");
  };

  //handleWhatsapp
  const handleWhatsapp = () => {
    window.open("http://wa.me/+233208659922", "_blank");
  };

  //handleEmail
  const handleEmail = () => {
    const mailtoLink = `mailto:info@oyaghana.com`;
    window.location.href = mailtoLink;
  };

  //handlePhoneCall
  const handlePhoneCall = (phoneNumber: any) => {
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  return (
    <>
      <div className="h-screen overflow-hidden bg-[#d7f5f555] relative">
        <div className="h-full p-5">
          <div className="flex justify-between items-center">
            <ArrowLeftIcon
              className="h-5 w-5 cursor-pointer"
              onClick={() => navigate("/settings")}
            />
            <h5 className="font-semibold text-center">Contact Us</h5>
            <div></div>
          </div>

          <div className="mt-7">
            <p className="font-semibold">Reach out to Customer Service</p>

            <div
              className="mt-3 cursor-pointer p-5 border-b-[1px] border-gray-400 flex justify-between items-center"
              onClick={() => handlePhoneCall("+233594107075")}
            >
              <div className="flex gap-4 items-center">
                <BiSolidPhoneCall className="h-5 w-5" />
                <p>059 410 7075</p>
              </div>
              <ChevronRightIcon className="h-5 w-5" />
            </div>

            <div
              className="mt-3 cursor-pointer p-5 border-b-[1px] border-gray-400 flex justify-between items-center"
              onClick={() => handlePhoneCall("+233208659922")}
            >
              <div className="flex gap-4 items-center">
                <BiSolidPhoneCall className="h-5 w-5" />
                <p>020 865 9922</p>
              </div>
              <ChevronRightIcon className="h-5 w-5" />
            </div>

            <div
              className="mt-3 cursor-pointer p-5 border-b-[1px] border-gray-400 flex justify-between items-center"
              onClick={() => handleEmail()}
            >
              <div className="flex gap-4 items-center">
                <BsEnvelopeAtFill className="h-5 w-5" />
                <p>info@oyaghana.com</p>
              </div>
              <ChevronRightIcon className="h-5 w-5" />
            </div>

            <div
              className="mt-3 cursor-pointer p-5 border-b-[1px] border-gray-400 flex justify-between items-center"
              onClick={() => handleWhatsapp()}
            >
              <div className="flex gap-4 items-center">
                <BsWhatsapp className="h-5 w-5" />
                <p>020 865 9922</p>
              </div>
              <ChevronRightIcon className="h-5 w-5" />
            </div>

            <div
              className="mt-3 cursor-pointer p-5 border-b-[1px] border-gray-400 flex justify-between items-center"
              onClick={() => handleFacebook()}
            >
              <div className="flex gap-4 items-center">
                <BsFacebook className="h-5 w-5" />
                <p>OyaGhana</p>
              </div>
              <ChevronRightIcon className="h-5 w-5" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
