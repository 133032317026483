import { Fragment, useState } from "react";
import CustomNavigation from "../../components/CustomNavigation";
// import {
//   EnvelopeIcon,
//   PowerIcon,
//   ShieldExclamationIcon,
// } from '@heroicons/react/24/outline';
import { UserIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { StorageBox } from "../../helpers/stroage";
import Header from "../../components/Header";
import LogoutModal from "./LogoutModal";

const SettingsScreen = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  //tab names
  const tabNames = [
    {
      name: "Personal",
      icon: <UserIcon className="h-6 w-6 " />,
      route: "/personal",
    },
    {
      name: "App info",
      icon: <InformationCircleIcon className="h-6 w-6 " />,
      route: "/app-info",
    },
  ];

  const handleLogout = () => {
    StorageBox.clearStorage();
    window.location.reload();
  };

  return (
    <Fragment>
      <div className="h-screen overflow-hidden bg-neutral-50 relative">
        <Header title="Settings" showExtra={false} />
        {/* head */}
        <div className="mt-5">
          <div className="h-full w-full">
            {tabNames.map((tab, i) => (
              <div
                key={i}
                className="cursor-pointer p-5 border-b-[1px] border-neutral-100 flex gap-4 items-center"
                onClick={() => navigate(tab.route)}
              >
                {tab?.icon}
                {tab?.name}
              </div>
            ))}
            <div
              className="cursor-pointer p-5 border-b-[1px] border-neutral-100 flex gap-4 items-center"
              onClick={() => setOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z"
                  fill="#484442"
                />
                <path
                  d="M4.55994 11.2498L6.62994 9.17984C6.77994 9.02984 6.84994 8.83984 6.84994 8.64984C6.84994 8.45984 6.77994 8.25984 6.62994 8.11984C6.33994 7.82984 5.85994 7.82984 5.56994 8.11984L2.21994 11.4698C1.92994 11.7598 1.92994 12.2398 2.21994 12.5298L5.56994 15.8798C5.85994 16.1698 6.33994 16.1698 6.62994 15.8798C6.91994 15.5898 6.91994 15.1098 6.62994 14.8198L4.55994 12.7498H8.99994V11.2498H4.55994Z"
                  fill="#484442"
                />
              </svg>
              <p className="text-primary">Logout</p>
            </div>
          </div>
        </div>

        {/* foot */}
        <div className="fixed w-full bottom-0 bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800">
          <CustomNavigation activeIndex={3} />
        </div>

        <LogoutModal
          isOpen={open}
          handleOpenDrawer={() => setOpen(false)}
          handleLogout={() => handleLogout()}
        />
      </div>
    </Fragment>
  );
};

export default SettingsScreen;
