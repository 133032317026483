import axios from 'axios'
import { StorageBox } from './stroage'
import { ACCOUNTS_URL, AUTHMS_URL, FUEL_MS_URL, PAYMENT_URL } from './constants'

//Axios Post
const post_api_auth = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.post(AUTHMS_URL + route, data, config)
}

const put_api_auth = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.put(AUTHMS_URL + route, data, config)
}

//Axios Get
const get_api = (route: string) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  return axios.get(FUEL_MS_URL + route, config)
}

//Axios Post
const post_api = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.post(FUEL_MS_URL + route, data, config)
}

//Axios Put
const put_api = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.put(FUEL_MS_URL + route, data, config)
}

//Axios Delete
const delete_api = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  var config = {
    method: 'delete',
    url: FUEL_MS_URL + route,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    data: data
  }

  return axios(config)
}

//Axios Put
const put_payment_api = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.put(PAYMENT_URL + route, data, config)
}

const account_ms_post_request = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.post(`${ACCOUNTS_URL}${route}`, data, config)
}

const account_ms_get_request = (route: string) => {
  const accessToken = StorageBox.getAccessToken()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  return axios.get(`${ACCOUNTS_URL}${route}`, config)
}

const account_ms_delete_request = (route: string, data: any) => {
  const accessToken = StorageBox.getAccessToken()
  var config = {
    method: 'delete',
    url: ACCOUNTS_URL + route,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    data: data
  }

  return axios(config)
}
const BaseService = {
  post_api,
  get_api,
  put_api,
  delete_api,
  post_api_auth,
  put_payment_api,
  account_ms_post_request,
  account_ms_get_request,
  account_ms_delete_request,
  put_api_auth
}

export default BaseService
