import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

const SecuritySettings = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="h-screen overflow-hidden bg-[#d7f5f555]">
        <div className="h-full px-[30px] mt-5">
          <div className="flex justify-between items-center">
            <button
              className="h-10 w-10 rounded-full bg-orange-500 text-white flex justify-center items-center"
              onClick={() => navigate("/settings")}
            >
              <ArrowLeftIcon className="h-5 w-5" />
            </button>
            <h5>Security</h5>
            <div></div>
          </div>

          <div className="mt-5">
            <div className="w-full h-auto p-5 bg-white shadow-lg flex justify-between items-center rounded-[15px]">
              <p className="font-semibold">Change your pin:</p>
              <Link to="/change-pin" className="font-semibold text-orange-500">
                Change
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SecuritySettings;
