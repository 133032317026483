import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Option, Select } from "@material-tailwind/react";
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput, { Value } from "react-phone-number-input";
import BaseService from "../../../../helpers/baseServices";
import { global_variables } from "../../../../helpers/globalVariables";
import Button from "../../../../components/Button";
import { debounce } from "lodash";
import ShowToast from "../../../../components/alerts/all_toasts";
import { Spinner } from "@material-tailwind/react";

const OtherFuel = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [phoneNum, setPhoneNum] = useState<Value | undefined>("");
  const [allCompanies, setAllCompanies] = useState([]);
  const [fecthingCompanies, setFecthingCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectError, setSelectError] = useState(false);
  const [theAmount, setTheAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [fuelType, setFuelType] = useState("");
  const [typeErr, setTypeErr] = useState(false);
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [allFuelTypes, setAllFuelTypes] = useState([]);

  useEffect(() => {
    fetchCompanies();
    fetchFuelTypes();
  }, []);

  //fetch comapnies
  const fetchCompanies = async () => {
    setFecthingCompanies(true);
    try {
      const response = await BaseService.get_api(
        `${global_variables.get_companies}`,
      );
      // console.log(response)
      setAllCompanies(response?.data?.payload?.items);
      setFecthingCompanies(false);
    } catch (error) {
      // console.log(error)
    }
  };

  //fetchFuelTypes
  const fetchFuelTypes = async () => {
    setFetchingTypes(true);
    try {
      const response = await BaseService.get_api(
        `${global_variables.get_fuel_types}`,
      );
      // console.log(response?.data)
      setAllFuelTypes(response?.data?.payload?.items);
      setFetchingTypes(false);
    } catch (error) {
      console.log(error);
    }
  };

  //handle on company change
  const onCompanyChange = (selected: any) => {
    // console.log(selected)
    setSelectedCompany(selected);
  };

  // handleOnPhoneChange
  const handleOnPhoneChange = debounce((pho: any) => {
    let formatPhoneNumber = pho?.replace(/\s+/g, "");
    // console.log(formatPhoneNumber)
    setPhoneNum(formatPhoneNumber);
    let data = {
      phone: formatPhoneNumber,
    };

    if (pho && pho.length >= 10) {
      setIsBusy(true);
      BaseService.post_api_auth(`${global_variables.find_user_by_phone}`, data)
        .then((response) => {
          // console.log(response)
          setFName(response?.data?.payload?.first_name);
          setLName(response?.data?.payload?.last_name);
          setIsBusy(false);
        })
        .catch((error) => {
          // console.log(error)
          ShowToast.error_toast(
            error?.response?.data?.message || error?.message,
          );
          setIsBusy(false);
          setFName("");
          setLName("");
        });
    }
  }, 2000);

  //handle continue
  const handleContinue = () => {
    if (!selectedCompany) {
      setSelectError(true);
    } else if (!fuelType) {
      setTypeErr(true);
    } else if (!theAmount) {
      setAmountError(true);
    } else {
      let dataForPayment = JSON.stringify({
        price: parseFloat(theAmount).toFixed(2),
        type: "ANOTHER",
        fuel_type_code: fuelType,
        company_code: selectedCompany,
        phone: phoneNum,
        first_name: fName,
        last_name: lName,
      });
      // console.log(JSON.parse(dataForPayment))
      localStorage.setItem("dataForOtherPayment", dataForPayment);
      navigate("/other-payment");
    }
  };

  return (
    <Fragment>
      <div className="h-screen overflow-hidden bg-neutral-50">
        <div className="h-full mx-[20px] mt-5">
          {step === 1 && (
            <>
              <div className="flex justify-between items-center">
                <div className="flex-[0.3]">
                  <button
                    className="h-10 text-neutral-300 flex justify-center items-center"
                    onClick={() => navigate("/home")}
                  >
                    <ChevronLeftIcon className="h-6 w-6 font-bold" />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <h4 className="text-header-4">Buy for others</h4>
                </div>
              </div>
              <div className="mt-5">
                <div>
                  <small
                    className={`${
                      phoneError
                        ? "text-red-500 ml-1 mb-2"
                        : "ml-1 mb-2 text-caption"
                    }`}
                  >
                    Person's mobile number
                  </small>
                  <PhoneInput
                    className="input-container"
                    defaultCountry="GH"
                    inputStyle={{ width: "100%" }}
                    value={phoneNum}
                    onChange={(phone) => handleOnPhoneChange(phone)}
                    numberInputProps={{
                      className: "focus:outline-none",
                    }}
                  />
                </div>
                <div className="text-gray-800 text-sm w-[80%]">
                  Wait a few seconds after entering the mobile number.
                </div>
                <div className="mt-5">
                  <small
                    className={`${
                      nameError
                        ? "text-red-500 ml-1 mb-2"
                        : "ml-1 mb-2 text-caption"
                    }`}
                  >
                    Person's first name
                  </small>
                  <input
                    className="input-container w-full"
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                    placeholder="Enter first name"
                    disabled={true}
                  />
                </div>

                <div className="mt-5">
                  <small
                    className={`${
                      nameError
                        ? "text-red-500 ml-1 mb-2"
                        : "ml-1 mb-2 text-caption"
                    }`}
                  >
                    Person's last name
                  </small>
                  <input
                    className="input-container w-full"
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                    placeholder="Enter last name"
                    disabled={true}
                  />
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="flex justify-between items-center">
                <div className="flex-[0.3]">
                  <button
                    className="h-10 text-neutral-300 flex justify-center items-center"
                    onClick={() => setStep(1)}
                  >
                    <ChevronLeftIcon className="h-6 w-6 font-bold" />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <h4 className="text-header-4">Buy for others</h4>
                </div>
              </div>
              <div className="mt-10">
                <div>
                  <small>Select Company</small>
                  <Select
                    label="Select"
                    placeholder=""
                    className="h-[50px] bg-white"
                    onChange={(val) => onCompanyChange(val)}
                    error={selectError}
                    disabled={fecthingCompanies}
                  >
                    {allCompanies.map((comapany: any, i) => (
                      <Option key={i} value={comapany?.code}>
                        {comapany.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="mt-5">
                  <small>Select Fuel Type</small>
                  <Select
                    label="Select"
                    placeholder=""
                    className="h-[50px] bg-white"
                    onChange={(val: any) => setFuelType(val)}
                    error={typeErr}
                    disabled={fetchingTypes}
                  >
                    {allFuelTypes.map((item: any, i: number) => (
                      <Option key={i} value={item?.code}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="mt-5">
                  <small className={`${amountError ? "text-red-500" : ""}`}>
                    Amount
                  </small>
                  <input
                    className="input-container w-full"
                    placeholder="Enter amount"
                    type="number"
                    value={theAmount}
                    onChange={(e) => setTheAmount(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          <div className="mt-[50px] flex justify-center items-center">
            {step === 1 && (
              <Button
                width="w-full"
                onClick={() => {
                  if (!fName || !lName) {
                    setNameError(true);
                  } else if (!phoneNum) {
                    setPhoneError(true);
                  } else {
                    setStep(2);
                  }
                }}
              >
                {isBusy ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <span>Continue</span>
                )}
              </Button>
            )}
            {step === 2 && (
              <Button
                width="w-full"
                onClick={() => {
                  handleContinue();
                }}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OtherFuel;
