import {
  ArrowDownTrayIcon,
  ChevronRightIcon,
  ClipboardDocumentIcon,
  // PlusIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import BaseService from "../helpers/baseServices";
import TopUpWalletModal from "./topUpWalletModal";
import { StorageBox } from "../helpers/stroage";
import WithdrawalModal from "./withdrawModal";
import AllWalletsPopUp from "./allWalletsPopUp";
import WalletHistory from "../pages/history/walletHistory";
import ChooseDrawer from "../pages/home/chooseDrawer";

const UserWallet = () => {
  const [isCopied, setIsCopied] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [walletData, setWalletData] = useState<any>([]);
  const [allWallets, setAllWallets] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  //open buy coupon
  const [openDrawer, setOpenDrawer] = useState(false);

  //open Top up modal
  const [openTopUp, setOpenTopUp] = useState(false);
  // const handleOpenTopUp = () => {
  //   setOpenTopUp(true);
  // };
  const handleCloseTopUp = () => {
    setOpenTopUp(false);
    handleReFetch();
  };
  //open withdrawl modal
  const [openWithDraw, setOpenWithdraw] = useState(false);
  const handleOpenWithDraw = () => {
    setOpenWithdraw(true);
  };
  const handleCloseWithDraw = () => {
    setOpenWithdraw(false);
    handleReFetch();
  };

  //open wallet history
  const [openWalletHistory, setOpenWalletHistory] = useState(false);

  //open switch modal
  const [openSwitch, setOpenSwitch] = useState(false);
  const handleOpenSwitch = () => {
    setOpenSwitch(true);
  };
  const handleCloseSwitch = () => {
    setOpenSwitch(false);
  };

  useEffect(() => {
    getWallets();
    const userD = StorageBox.retrieveUserData();
    // console.log(userD)
    setUserData(userD);
  }, [reFetch]);

  //get wallets
  const getWallets = () => {
    setIsFetching(true);
    BaseService.get_api(`/me/wallets`)
      .then((res) => {
        // console.log(res?.data?.payload)
        setAllWallets(res?.data?.payload?.items);
        setWalletData(res?.data?.payload?.items[0]);
        setWalletData(res?.data?.payload?.items[0]);
        StorageBox.saveWalletId(res?.data?.payload?.items[0]?.id);
        setIsFetching(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handleReFetch
  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  //handleCopyWalletId
  const handleCopyWalletId = () => {
    const walletId = walletData?.number;
    // Create a textarea element to hold the text to be copied
    const textArea = document.createElement("textarea");
    textArea.value = walletId;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

    // Set copied state to true and reset after a short delay
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <>
      <div className="mt-3 mx-4 ">
        <div className="wallet-container text-white rounded-[16px] px-2 py-4">
          <div className="">
            {isFetching ? (
              <div className="animate-pulse">
                <p className="font-medium text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg"></p>

                <small className="mt-1 text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg">
                  *********************
                </small>
              </div>
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <div className="flex w-full justify-between items-center">
                    <div className="font-semibold text-white h-fit">
                      {userData?.user?.name}
                    </div>
                    <button
                      onClick={() => setOpenWalletHistory(true)}
                      className="flex gap-1 items-center font-light text-sm"
                    >
                      <div>Wallet History</div>
                      <ChevronRightIcon className="w-4 h-4" />
                    </button>
                  </div>
                  {allWallets.length > 1 ? (
                    <button
                      className="font-semibold italic flex gap-1 items-center"
                      onClick={() => handleOpenSwitch()}
                    >
                      Switch
                      <ChevronDownIcon className="h-4 w-5" />
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex gap-2 items-center">
                  <div className=" text-white mt-1">
                    <div>
                      <div className="font-light text-xs">Wallet ID</div>
                      <div className="flex gap-2 items-center">
                        <div className="text-sm">{walletData?.number}</div>
                        {isCopied ? (
                          <small className="text-white ml-1 mt-1">
                            Copied!
                          </small>
                        ) : (
                          <ClipboardDocumentIcon
                            className=" w-4 h-4 text-white cursor-pointer"
                            onClick={handleCopyWalletId}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="mt-1 flex justify-between items-center">
            {isFetching ? (
              <div className="animate-pulse mt-3">
                <p className="font-medium text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg">
                  GHS 0.000
                </p>
              </div>
            ) : (
              <div className="flex gap-3 items-center h-fit">
                <div color="white">
                  {`${walletData?.balance?.currency} ${parseFloat(
                    walletData?.balance?.value
                  ).toFixed(2)}`}
                </div>
              </div>
            )}
          </div>
          <div className="mt-3 flex justify-end items-center gap-4">
            <button
              className="py-2 px-2 rounded-[8px] text-white border border-white flex justify-center items-center gap-1"
              onClick={() => handleOpenWithDraw()}
            >
              <ArrowDownTrayIcon className="h-4 w-4 text-white" />
              <div className="text-xs">Withdraw</div>
            </button>
            {/* <button
              className="px-2 py-2 rounded-[5px] text-primary bg-white flex justify-center items-center gap-1"
              onClick={() => handleOpenTopUp()}
            >
              <PlusIcon className="h-4 w-4 text-primary" />
              <div className="text-xs">Top Up</div>
            </button> */}
            {/* <button
              className='w-full py-2 rounded-[5px] text-white bg-black bg-opacity-[25%] flex justify-center items-center gap-2'
              onClick={() => navigate('/transactions')}
            >
              <ArchiveBoxIcon className='h-4 w-4 text-white' />
              History
            </button> */}
          </div>
        </div>
      </div>
      {/* <div className='h-fit bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800 rounded-b-[20px] p-5'>
                <div className=''>
                    {
                        isFetching ? <div className='animate-pulse'>
                            <p className='font-medium text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg'>Avagah Gasikah Jonathan</p>

                            <small className='mt-1 text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg'>*********************</small>

                        </div> :
                            <>
                                <div className='flex justify-between items-center'>
                                    <div variant="h5" className="italic font-medium text-white h-fit">{userData?.user?.name}</div>
                                    {
                                        allWallets.length > 1 ?
                                            <button className='font-semibold italic flex gap-1 items-center' onClick={() => handleOpenSwitch()}>
                                                Switch
                                                <ChevronDownIcon className='h-4 w-5' />
                                            </button>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <small className='flex gap-2 items-center text-white mt-1'>Wallet No.: {walletData?.number}
                                    </small>
                                    {isCopied ? <small className='text-gray-900 ml-1 mt-1'>Copied!</small> :
                                        <ClipboardDocumentIcon
                                            className=' w-4 h-4 text-gray-900 cursor-pointer'
                                            onClick={handleCopyWalletId}
                                        />
                                    }
                                </div>
                            </>
                    }
                </div>

                <div className='mt-1 flex justify-between items-center'>
                    {
                        isFetching ? <div className='animate-pulse mt-3'>
                            <p className='font-medium text-transparent bg-black bg-opacity-[50%] h-fit w-fit rounded-lg'>GHS 0.000</p>
                        </div> :
                            <div className='flex gap-3 items-center h-fit'>
                                
                                <div variant="h4" color="white"  >
                                    {`${walletData?.balance?.currency} ${parseFloat(walletData?.balance?.value).toFixed(2)}`}
                                </div>
                            </div>
                    }
                </div>
                <div className='mt-3 flex justify-between items-center gap-4'>
                    <button className='w-full py-2 px-2 rounded-[5px] text-white bg-black bg-opacity-[25%] flex justify-center items-center gap-2' onClick={() => handleOpenWithDraw()}>
                        <ArrowDownLeftIcon className='h-4 w-4 text-white' />
                        Withdraw
                    </button>
                    <button className='w-full py-2 rounded-[5px] text-white bg-black bg-opacity-[25%] flex justify-center items-center gap-2' onClick={() => handleOpenTopUp()}>
                        <ArrowUpRightIcon className='h-4 w-4 text-white' />
                        Top Up
                    </button>
                    <button className='w-full py-2 rounded-[5px] text-white bg-black bg-opacity-[25%] flex justify-center items-center gap-2' onClick={() => navigate("/transactions")}>
                        <ArchiveBoxIcon className='h-4 w-4 text-white' />
                        History
                    </button>
                </div>
            </div> */}

      {openDrawer && (
        <ChooseDrawer
          openDrawer={openDrawer}
          handleOpenDrawer={() => setOpenDrawer(false)}
        />
      )}

      {openWalletHistory && (
        <WalletHistory
          isOpen={openWalletHistory}
          handleBuyFuel={() => {
            setOpenWalletHistory(false);
            setOpenDrawer(true);
          }}
          handleTopUp={() => {
            setOpenWalletHistory(false);
            setOpenTopUp(true);
          }}
          handleCloseDrawer={() => setOpenWalletHistory(false)}
        />
      )}

      {/* open topup */}
      {openTopUp ? (
        <TopUpWalletModal
          openDrawer={openTopUp}
          handleCloseDrawer={handleCloseTopUp}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
      {/* open withdraw */}
      {openWithDraw ? (
        <WithdrawalModal
          openDrawer={openWithDraw}
          handleCloseDrawer={handleCloseWithDraw}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
      {/* open switch */}
      {openSwitch ? (
        <AllWalletsPopUp
          openDrawer={openSwitch}
          handleCloseDrawer={handleCloseSwitch}
          selectedWallet={setWalletData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UserWallet;
