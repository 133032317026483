export const global_variables = {
  // auth routes
  signinRoute: "/auth/login",

  get_stats: "/me/stats",
  get_coupons: "/me/coupons",
  get_one_coupon: "/coupons",
  create_coupon: "/coupons",
  get_companies: "/companies",
  get_providers: "/providers",
  wallets_route: "/me/wallets",
  get_tranasaction: "/transactions",
  find_user_by_phone: "/v2/find-by-phone",
  get_fuel_types: "/fuel-types",
  get_fuel_stations: "/branches",
};
