import { Fragment } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const LogoutModal = ({
  isOpen,
  handleOpenDrawer,
  handleLogout,
}: {
  isOpen: boolean;
  handleOpenDrawer: () => void;
  handleLogout: () => void;
}) => {
  return (
    <Fragment>
      <Dialog placeholder="" open={isOpen} handler={handleOpenDrawer}>
        <DialogHeader placeholder="">Log out</DialogHeader>
        <DialogBody placeholder="">
          Are you sure you want to log out from this device?
        </DialogBody>
        <DialogFooter placeholder="">
          <div className="flex items-center gap-4">
            <button
              onClick={() => handleOpenDrawer()}
              className="font-semibold"
            >
              Cancel
            </button>
            <button className="font-semibold" onClick={() => handleLogout()}>
              Logout
            </button>
          </div>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

export default LogoutModal;
