import { Fragment, useState, useEffect } from 'react';
import CustomNavigation from '../../components/CustomNavigation';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { RouteConstants } from '../../helpers/RouteConstants';
import Header from '../../components/Header';
import BaseService from '../../helpers/baseServices';
import { useNavigate } from 'react-router-dom';

type Props = {};

const Regions = (props: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allRegions, setAllRegions] = useState([]);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  //getAllFuelregiones
  const getAllFuelregiones = async (lim: number) => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(
        `${RouteConstants.ALL_SUPPORTED_REGIONS}?limit=${lim}`
      );
      // console.log(response?.data)
      setAllRegions(response?.data?.payload?.items);
      setTotal(response?.data?.payload?.total);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  //onload
  useEffect(() => {
    getAllFuelregiones(limit);
  }, [limit]);

  //loadNext
  const loadNext = () => {
    setLimit(limit + 12);
  };

  return (
    <Fragment>
      <div className='h-screen overflow-hidden bg-neutral-50 relative'>
        <div className='pb-[120px] h-screen overflow-hidden'>
          <Header title='Fuel Stations' showExtra={false} />
          {/* <div className='w-full bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800 h-fit py-3'>
            <div className='font-semibold text-center text-white'>
              Fuel Stations
            </div>
          </div> */}
          {/* head */}
          <div className='h-full overflow-y-auto'>
            <div className='h-full w-full'>
              {isFetching
                ? ['', '', '', '', '', '', '', '', '', '', '', ''].map(
                    (dd, i) => (
                      <div
                        key={i}
                        className='cursor-pointer p-5 border-b-[1px] border-white animate-pulse flex gap-3 items-center'
                      >
                        <div className='w-5 h-5 text-transparent bg-gray-300'></div>
                        <p className='w-[200px] bg-gray-300 h-2 rounded-md text-transparent'></p>
                      </div>
                    )
                  )
                : allRegions.map((region: any) => (
                    <>
                      <button
                        onClick={() => {
                          localStorage.setItem('rnm', region?.name);
                          navigate(`/branches/${region?.id}`);
                        }}
                        key={region?.id}
                        className='cursor-pointer p-5 border-b-[1px] border-white flex gap-3 items-center justify-between w-full'
                      >
                        <div className='flex justify-center items-center gap-2'>
                          <div className='rounded-[12px] p-[8px]  bg-primary'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20'
                              height='20'
                              viewBox='0 0 20 20'
                              fill='none'
                            >
                              <path
                                d='M17.1834 7.04134C16.3084 3.19134 12.9501 1.45801 10.0001 1.45801C10.0001 1.45801 10.0001 1.45801 9.99175 1.45801C7.05008 1.45801 3.68341 3.18301 2.80841 7.03301C1.83341 11.333 4.46675 14.9747 6.85008 17.2663C7.73341 18.1163 8.86675 18.5413 10.0001 18.5413C11.1334 18.5413 12.2667 18.1163 13.1417 17.2663C15.5251 14.9747 18.1584 11.3413 17.1834 7.04134ZM10.0001 11.2163C8.55008 11.2163 7.37508 10.0413 7.37508 8.59134C7.37508 7.14134 8.55008 5.96634 10.0001 5.96634C11.4501 5.96634 12.6251 7.14134 12.6251 8.59134C12.6251 10.0413 11.4501 11.2163 10.0001 11.2163Z'
                                fill='white'
                              />
                            </svg>
                          </div>
                          <div className='text-header-4'>{region?.name}</div>
                        </div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            d='M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008'
                            stroke='#292D32'
                            strokeWidth='1.5'
                            strokeMiterlimit='10'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </>
                  ))}

              {isFetching ? (
                <></>
              ) : (
                <>
                  {limit >= total ? (
                    <></>
                  ) : (
                    <div className='mt-5 pb-10 flex justify-end'>
                      <button
                        className='py-1 px-3 w-fit rounded-[5px] text-white bg-orange-800 flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed'
                        onClick={() => loadNext()}
                        disabled={limit >= total}
                      >
                        More
                        <ChevronDownIcon className='w-4 h-4 text-white' />
                      </button>
                    </div>
                  )}
                </>
              )}
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        {/* {
                    limit >= total ?
                        <></>
                        :
                        <div className='mb-20 flex justify-center items-center absolute bottom-[-20px] left-0 right-0'>
                            <button className='px-3 py-1 rounded-[5px] bg-orange-800 text-white flex justify-center items-center gap-2 disabled:bg-gray-400 disabled:cursor-not-allowed' onClick={() => onLoadMore()} disabled={limit >= total}>
                                View More
                                <ChevronDoubleDownIcon className='h-4 w-4' />
                            </button>
                        </div>
                } */}

        {/* foot */}
        <div className='fixed w-full bottom-0 bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800'>
          <CustomNavigation activeIndex={2} />
        </div>
      </div>
    </Fragment>
  );
};

export default Regions;
