import { Fragment, useState } from 'react';
import { Drawer, IconButton } from '@material-tailwind/react';
import { Checkbox, Space } from 'antd';
import moment from 'moment';

type OwnershipTypes = 'ANOTHER' | 'SELF' | 'OTHERS';

const FilterModal = ({
  isOpen,
  handleOpenDrawer,
  type,
  handleReset,
  applyFilter,
  handleToDate,
  handleFromDate,
  handleOwnership,
}: {
  isOpen: boolean;
  handleOpenDrawer: () => void;
  type: string;
  handleReset: () => void;
  applyFilter: (
    ownership: OwnershipTypes | null,
    from: Date | null,
    to: Date | null
  ) => void;
  handleToDate: (date: Date | null) => void;
  handleFromDate: (date: Date | null) => void;
  handleOwnership?: (value: OwnershipTypes | null) => void;
}) => {
  const [selectedOwnership, setSelectedOwnership] =
    useState<OwnershipTypes | null>(null);
  const [fromDate, setFromDate] = useState<string | any | null>(null);
  const [toDate, setToDate] = useState<string | null | any>(null);

  return (
    <Fragment>
      <Drawer
        placeholder=""
        placement="bottom"
        size={440}
        open={isOpen}
        className=" rounded-t-[30px] shadow-xl bg-white"
      >
        <div>
          <div className="mb-3 flex items-center justify-between pt-8 px-5">
            <div className="text-header-4">Filter by</div>
            <IconButton
              variant="text"
              color="blue-gray"
              onClick={handleOpenDrawer}
              placeholder=""
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M10.3519 21.9445C10.0083 21.9445 9.66463 21.8179 9.39335 21.5466C8.86888 21.0222 8.86888 20.1541 9.39335 19.6296L19.6296 9.39335C20.1541 8.86888 21.0222 8.86888 21.5466 9.39335C22.0711 9.91783 22.0711 10.7859 21.5466 11.3104L11.3104 21.5466C11.0572 21.8179 10.6955 21.9445 10.3519 21.9445Z"
                  fill="#484442"
                />
                <path
                  d="M20.5881 21.9445C20.2445 21.9445 19.9009 21.8179 19.6296 21.5466L9.39335 11.3104C8.86888 10.7859 8.86888 9.91783 9.39335 9.39335C9.91783 8.86888 10.7859 8.86888 11.3104 9.39335L21.5466 19.6296C22.0711 20.1541 22.0711 21.0222 21.5466 21.5466C21.2754 21.8179 20.9317 21.9445 20.5881 21.9445Z"
                  fill="#484442"
                />
              </svg>
            </IconButton>
          </div>
          <div className="mt-5 pb-4 mx-[20px]">
            <div className="text-neutral-300 font-semibold">Date</div>
            <div className="flex gap-5 items-center justify-between">
              <div>
                <div className="text-caption ml-1 py-1">From</div>
                <input
                  type="date"
                  value={fromDate}
                  className="input-container w-full"
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    handleFromDate(moment(e.target.value).toDate());
                  }}
                />
              </div>
              <div>
                <div className="text-caption ml-1 py-1">To</div>
                <input
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                    handleToDate(moment(e.target.value).toDate());
                  }}
                  type="date"
                  className="input-container w-full"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-5 mx-[20px]">
            <div className="text-neutral-300 font-semibold">
              Coupon ownership
            </div>
            <div className="mt-2">
              {type === 'all' && (
                <Space direction="vertical">
                  <Checkbox
                    checked={selectedOwnership === 'SELF' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('SELF');
                      if (handleOwnership) {
                        handleOwnership('SELF');
                      }
                    }}
                  >
                    Self
                  </Checkbox>
                  <Checkbox
                    checked={selectedOwnership === 'OTHERS' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('OTHERS');
                      if (handleOwnership) {
                        handleOwnership('OTHERS');
                      }
                    }}
                  >
                    Others
                  </Checkbox>
                  <Checkbox
                    checked={selectedOwnership === 'ANOTHER' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('ANOTHER');
                      if (handleOwnership) {
                        handleOwnership('ANOTHER');
                      }
                    }}
                  >
                    Purchased for you
                  </Checkbox>
                </Space>
              )}
              {type === 'used' && (
                <Space direction="vertical">
                  <Checkbox
                    checked={selectedOwnership === 'SELF' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('SELF');
                      if (handleOwnership) {
                        handleOwnership('SELF');
                      }
                    }}
                  >
                    Self
                  </Checkbox>

                  <Checkbox
                    checked={selectedOwnership === 'ANOTHER' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('ANOTHER');
                      if (handleOwnership) {
                        handleOwnership('ANOTHER');
                      }
                    }}
                  >
                    Purchased for you
                  </Checkbox>
                </Space>
              )}
              {type === 'paid' && (
                <Space direction="vertical">
                  <Checkbox
                    checked={selectedOwnership === 'SELF' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('SELF');
                      if (handleOwnership) {
                        handleOwnership('SELF');
                      }
                    }}
                  >
                    Self
                  </Checkbox>
                  <Checkbox
                    checked={selectedOwnership === 'OTHERS' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('OTHERS');
                      if (handleOwnership) {
                        handleOwnership('OTHERS');
                      }
                    }}
                  >
                    Others
                  </Checkbox>
                  <Checkbox
                    checked={selectedOwnership === 'ANOTHER' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('ANOTHER');
                      if (handleOwnership) {
                        handleOwnership('ANOTHER');
                      }
                    }}
                  >
                    Purchased for you
                  </Checkbox>
                </Space>
              )}
              {type === 'pending' && (
                <Space direction="vertical">
                  <Checkbox
                    checked={selectedOwnership === 'SELF' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('SELF');
                      if (handleOwnership) {
                        handleOwnership('SELF');
                      }
                    }}
                  >
                    Self
                  </Checkbox>
                  <Checkbox
                    checked={selectedOwnership === 'OTHERS' ? true : false}
                    onClick={() => {
                      setSelectedOwnership('OTHERS');
                      if (handleOwnership) {
                        handleOwnership('OTHERS');
                      }
                    }}
                  >
                    Others
                  </Checkbox>
                </Space>
              )}
            </div>
          </div>
          <div className="flex items-center justify-evenly mx-[20px] mt-8">
            <button
              onClick={() => {
                setSelectedOwnership(null);
                setFromDate('');
                setToDate('');
                handleReset();
              }}
              className="btn-subtle"
            >
              Clear filters
            </button>
            <button
              onClick={() => {
                let from = moment(fromDate).toDate();
                let to = moment(toDate).toDate();
                applyFilter(selectedOwnership, from, to);
              }}
              className="rounded-[8px] bg-primary py-2 px-8 text-white "
            >
              Apply
            </button>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default FilterModal;
