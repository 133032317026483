import { Fragment, useEffect, useState } from 'react'
import BaseService from '../../helpers/baseServices'
import {
  ChevronDoubleDownIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import { formatHugeAmount } from '../../utils/funtions';
import { RouteConstants } from '../../helpers/RouteConstants'
import boxImg from '../../images/empty box.png'
import ChooseDrawer from './chooseDrawer'
import Button from '../../components/Button'
import TransferCouponModal from './transferCouponModal'

const CouponsScreen = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [stats, setStats] = useState<any>({})
  const [couponsData, setCouponsData] = useState<any>([])
  const [openBottom, setOpenBottom] = useState(false)
  const navigate = useNavigate()
  const [limit, setLimit] = useState(12)
  const [total, setTotal] = useState(0)
  const [openTransfer, setOpenTransfer] = useState(false)
  const [couponId, setCouponId] = useState('')

  const handleOpenTransfer = (id: string) => {
    handleOpenModal()
    setCouponId(id)
  }

  const handleCloseTransfer = () => setOpenTransfer(false)

  const handleOpenModal = () => {
    setOpenTransfer(true)
  }

  const openDrawerBottom = () => {
    setOpenBottom(!openBottom)
  }

  //fetch stats
  const fetchStats = async () => {
    setIsLoading(true)
    try {
      const response = await BaseService.get_api(`${RouteConstants.ALL_STATS}`)
      // console.log(response)
      setStats(response.data?.payload)
      setIsLoading(false)
    } catch (error) {
      // console.log(error)
    }
  }

  //fetch paid coupons
  const fetchPaidCoupons = async (lim: number) => {
    setIsLoading(true)
    try {
      const response = await BaseService.get_api(
        `${RouteConstants.MY_PAID_COUPONS}?sorting=updated_at:desc&limit=${lim}`
      )
      // console.log(response)
      setCouponsData(response?.data?.payload?.items)
      setTotal(response?.data?.payload?.total)
      setIsLoading(false)
    } catch (error) {
      // console.log(error)
    }
  }

  //handle on coupon select
  const onCouponSelect = (selected: any) => {
    navigate(`/coupons/${selected?.id}`)
  }

  //onLoadMore
  const onLoadMore = () => {
    if (limit < total) {
      setLimit(limit + 12)
    }
  }

  useEffect(() => {
    fetchStats()
    fetchPaidCoupons(limit)

    // const handleScroll = () => {
    //     if (
    //         window.innerHeight + document.documentElement.scrollTop ===
    //         document.documentElement.offsetHeight + 3
    //     ) {
    //         setLimit((prevLimit) => prevLimit + 3);
    //         fetchAllCoupons(limit)
    //     }
    // };

    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //     window.removeEventListener("scroll", handleScroll);
    // };
  }, [limit])

  return (
    <Fragment>
      {openBottom ? (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => openDrawerBottom()}
        />
      ) : (
        <></>
      )}
      <div>
        {isLoading ? (
          <>
            {['', '', '', '', ''].map((dd, i) => (
              <div
                key={i}
                className='w-full bg-white shadow-lg p-5 rounded-[10px] flex justify-between mb-3 animate-pulse'
              >
                <div>
                  <p className='font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg'>
                    GHS 100.00
                  </p>
                  <p className='text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg'>
                    Self
                  </p>
                </div>

                <div>
                  <p className='text-xs font-medium text-transparent bg-gray-400 h-3 w-fit rounded-lg'>
                    Do MMM YYYY (hh:mm A)
                  </p>
                  <p className='text-sm text-transparent bg-gray-400 h-2 w-fit mt-2 rounded-lg'>
                    Not Paid
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {couponsData.length === 0 ? (
              <div className='flex flex-col justify-center items-center h-full'>
                <img className='mt-10 lg:w-[300px]' src={boxImg} alt='empty' />
                <div className='text-center'>
                  You have no coupons yet.{' '}
                  <button
                    onClick={() => openDrawerBottom()}
                    className='text-[#5C8BFF] font-bold'
                  >
                    Click here
                  </button>{' '}
                  to buy coupon.
                </div>
              </div>
            ) : (
              <>
                <div className='pb-[150px]'>
                  <div className='my-5 w-full card-small-container'>
                    <div className='text-[14px]'>Available Coupons</div>
                    <div className='flex items-center justify-between w-full'>
                      <div className='flex items-center gap-1'>
                        <div className='colored-card-container bg-[#DCFFF1]'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
                              stroke='#2ABB7F'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                            <path
                              d='M6 11.9534L9.95342 15.9068L17.84 8'
                              stroke='#2ABB7F'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                        <div className='text-left'>
                          <div className='font-semibold'>
                            GHS
                            {Intl.NumberFormat('en-EN').format(
                              stats?.total_coupons
                            )}
                          </div>
                          <div className='text-neutral-200 text-xs'>
                            {stats?.total_coupons_paid}
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate('/all-coupons?q=paid')}
                        className='btn-subtle flex gap-2 items-center'
                      >
                        <div>See all</div>
                        <ChevronRightIcon className='w-5 h-5' />
                      </button>
                    </div>
                  </div>
                  {couponsData.map((coupon: any, i: number) => (
                    <div
                      key={i}
                      className='coupon-card-container cursor-pointer mb-5'
                    >
                      <div className='bg-[#DCFFF1] w-[39px] h-[22px] rounded-[8px] px-[8px] py-[4px] text-[12px] text-ks-green-500'>
                        {coupon?.payment_status?.name}
                      </div>

                      <div className='my-4'>
                        <div className='flex items-center gap-2'>
                          <div className='w-[48px] h-[48px]'>
                            <img
                              src={coupon?.company?.image_url}
                              alt='company'
                            />
                            <div className='text-primary font-semibold text-center'>
                              {coupon?.company?.name}
                            </div>
                          </div>
                          <div>
                            <div className='text-header-4'>
                              {coupon?.currency}
                              {Intl.NumberFormat('en-EN').format(
                                Number(coupon?.amount_left)
                              )}
                            </div>
                            <div className='text-xs text-neutral-200'>
                              {coupon?.currency}
                              {Intl.NumberFormat('en-EN').format(
                                Number(coupon?.amount_used)
                              )}{' '}
                              used
                            </div>
                            <div className='text-xs text-neutral-200'>
                              Purchased on{' '}
                              {moment(coupon?.created_at).format('Do MMM YY')}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='flex justify-end items-end w-full gap-[20px]'>
                        <button
                          onClick={() => onCouponSelect(coupon)}
                          className='btn-small-outline'
                        >
                          View
                        </button>
                        <button
                          className='btn-small-primary'
                          onClick={() => handleOpenTransfer(coupon?.id)}
                        >
                          <div className='text-white flex items-center gap-1'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                            >
                              <path
                                d='M5.99992 14.6668H9.99992C13.3333 14.6668 14.6666 13.3335 14.6666 10.0002V6.00016C14.6666 2.66683 13.3333 1.3335 9.99992 1.3335H5.99992C2.66659 1.3335 1.33325 2.66683 1.33325 6.00016V10.0002C1.33325 13.3335 2.66659 14.6668 5.99992 14.6668Z'
                                stroke='white'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M7.06006 5.12012H9.88673V7.95345'
                                stroke='white'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M9.88662 5.12012L6.11328 8.89345'
                                stroke='white'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                              <path
                                d='M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068'
                                stroke='white'
                                stroke-width='1.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </svg>
                            <div>Transfer</div>
                          </div>
                        </button>
                      </div>
                    </div>
                  ))}

                  {limit >= total ? (
                    <></>
                  ) : (
                    <div className='pb-14 flex justify-center items-center'>
                      <Button
                        onClick={() => onLoadMore()}
                        disabled={limit >= total}
                      >
                        <div className='flex items-center gap-2'>
                          View More
                          <ChevronDoubleDownIcon className='h-4 w-4' />
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </>
            )}
          </>
        )}
      </div>
      {openTransfer ? (
        <TransferCouponModal
          openModal={openTransfer}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseTransfer}
          couponId={couponId}
        />
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default CouponsScreen
