import { Fragment } from "react";
import CustomNavigation from "../../components/CustomNavigation";
import CouponsScreen from "./coupons";
// import UserWallet from "../../components/userWallet";
import FLoatingAction from "../../components/floating-action";
import Header from "../../components/Header";
import UserWallet from "../../components/userWallet";
// import UserWallet from '../../components/userWallet'
//import AvailableCouponsCard from '../../components/AvailableCouponsCard'

const HomeScreen = () => {
  return (
    <Fragment>
      <Header title="Home" showExtra={true} />
      <UserWallet />

      <div className="bg-[#d7f5f555] relative h-screen overflow-hidden px-5">
        {/* wallet */}
        {/* <AvailableCouponsCard /> */}

        <div className="h-full w-full overflow-hidden">
          {/* main content */}
          <div className="h-screen overflow-y-auto">
            <CouponsScreen />
          </div>
        </div>
        <FLoatingAction />
      </div>
      <div className="fixed w-full bottom-0 bg-gradient-to-r from-orange-700 via-orange-800 to-orange-800">
        <CustomNavigation activeIndex={0} />
      </div>
    </Fragment>
  );
};

export default HomeScreen;
