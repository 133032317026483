import { Fragment } from "react";
import { Drawer, IconButton } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface drawerProps {
  openDrawer: boolean;
  handleOpenDrawer: () => void;
}

const ChooseDrawer = ({ openDrawer, handleOpenDrawer }: drawerProps) => {
  return (
    <Fragment>
      <Drawer
        placement="bottom"
        open={openDrawer}
        onClose={handleOpenDrawer}
        placeholder=""
        className="p-4 rounded-t-[20px] shadow-xl"
        size={160}
      >
        <div className=" flex items-center justify-between">
          <h4 className="text-header-4">Buy fuel coupon</h4>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={handleOpenDrawer}
            placeholder=""
          >
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </div>

        <div className="flex flex-col h-full">
          <Link to="/self-fuel" className=" text-primary mt-5">
            For self
          </Link>
          <div className="w-full h-[1px] bg-neutral-100 my-2" />
          <Link to="/other-fuel" className=" text-primary">
            For others
          </Link>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default ChooseDrawer;
