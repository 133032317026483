import React from 'react';
import moment from 'moment';
import gasStation from '../../src/images/gas-station.png';

const CouponCard = ({
  textColor,
  bgColor,
  title,
  menu,
  data,
  actions,
}: {
  textColor: string;
  bgColor: string;
  title: string;
  menu?: React.ReactNode;
  data: any;
  actions: React.ReactNode;
}) => {
  return (
    <div className="coupon-card-container">
      <div className="flex items-center justify-between">
        <div
          className={`bg-${bgColor} rounded-[8px] px-[8px] py-[4px] text-[12px] text-${textColor}`}
        >
          {title}
        </div>
        {menu && <button>{menu}</button>}
      </div>
      <div className="my-4">
        <div className="flex items-center gap-2">
          <div className="w-[48px] h-[48px]">
            {data?.company?.image_url ? (
              <img src={data?.company?.image_url} alt="company" />
            ) : (
              <img src={gasStation} alt="" />
            )}
            <div className="text-primary font-semibold text-center overflow-ellipsis overflow-hidden w-14 whitespace-nowrap">
              {data?.company?.name}
            </div>
          </div>
          <div>
            <div className="text-header-4">
              {data?.currency}
              {Intl.NumberFormat('en-EN').format(Number(data?.amount_left))}
            </div>
            <div className="text-xs text-neutral-200">
              {data?.currency}
              {Intl.NumberFormat('en-EN').format(
                Number(data?.amount_used)
              )}{' '}
              used
            </div>
            <div className="text-xs text-neutral-200">
              Purchased on {moment(data?.created_at).format('Do MMM YY')}
            </div>
          </div>
        </div>
      </div>

      <div>{actions}</div>
    </div>
  );
};

export default CouponCard;
