import { Fragment } from "react";
import { Dialog } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  isOpen: boolean;
  handleClosed: () => void;
}

const CloseVerificationModal = ({ isOpen, handleClosed }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        size="md"
        handler={() => handleClosed()}
        placeholder=""
      >
        <div className="p-5">
          <div className="header-text-3">Phone verification</div>
          <div className="body-text-2 py-4">
            You are about to cancel the verification process
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={() => handleClosed()}
              className="bg-green-500 text-white flex justify-center items-center p-3 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={() => navigate("/")}
              className="bg-red-500 text-white flex justify-center items-center p-3 rounded-lg"
            >
              Proceed
            </button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default CloseVerificationModal;
