import { Fragment, useEffect, useState } from "react";
import { Drawer, IconButton } from "@material-tailwind/react";
import Button from "../../components/Button";
import { StorageBox } from "../../helpers/stroage";
import { RouteConstants } from "../../helpers/RouteConstants";
import {
  ChevronDoubleDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import BaseService from "../../helpers/baseServices";

const WalletHistory = ({
  isOpen,
  handleCloseDrawer,
  handleTopUp,
  handleBuyFuel,
}: {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  handleTopUp: () => void;
  handleBuyFuel: () => void;
}) => {
  const [allTransactions, setAllTransactions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);

  //on mount
  useEffect(() => {
    const theWalletId = StorageBox.getWalletId();

    getTransactions(theWalletId, limit);
  }, [limit]);

  //get all transactions
  const getTransactions = async (walledId: any, lim: number) => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(
        `${RouteConstants.WALLET_ROUTE}/${walledId}/transactions?sorting=created_at:desc&limit=${lim}`
      );
      // console.log(response?.data?.payload)
      setAllTransactions(response?.data?.payload?.items);
      setTotal(response?.data?.payload?.total);
      setIsFetching(false);
    } catch (error) {
      // console.log(error)
    }
  };

  //onLoadMore
  const onLoadMore = () => {
    if (limit < total) {
      setLimit(limit + 12);
    }
  };

  return (
    <Fragment>
      <Drawer
        placement="bottom"
        className="p-4 rounded-t-[30px] shadow-xl bg-neutral-50"
        size={750}
        open={isOpen}
        placeholder=""
      >
        <div>
          <div className="mb-3 flex items-center justify-between">
            <div className="text-header-4">Wallet History</div>
            <IconButton
              variant="text"
              color="blue-gray"
              onClick={handleCloseDrawer}
              placeholder=""
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M10.3519 21.9445C10.0083 21.9445 9.66463 21.8179 9.39335 21.5466C8.86888 21.0222 8.86888 20.1541 9.39335 19.6296L19.6296 9.39335C20.1541 8.86888 21.0222 8.86888 21.5466 9.39335C22.0711 9.91783 22.0711 10.7859 21.5466 11.3104L11.3104 21.5466C11.0572 21.8179 10.6955 21.9445 10.3519 21.9445Z"
                  fill="#484442"
                />
                <path
                  d="M20.5881 21.9445C20.2445 21.9445 19.9009 21.8179 19.6296 21.5466L9.39335 11.3104C8.86888 10.7859 8.86888 9.91783 9.39335 9.39335C9.91783 8.86888 10.7859 8.86888 11.3104 9.39335L21.5466 19.6296C22.0711 20.1541 22.0711 21.0222 21.5466 21.5466C21.2754 21.8179 20.9317 21.9445 20.5881 21.9445Z"
                  fill="#484442"
                />
              </svg>
            </IconButton>
          </div>

          <div className="mt-8">
            {isFetching ? (
              ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
                (dd, i) => (
                  <div
                    key={i}
                    className="p-3 mb-3 border-b border-b-gray-300 bg-gray-300 flex justify-between animate-pulse"
                  >
                    <div className="flex gap-3">
                      <div className="px-4 py-1  text-transparent w-fit h-fit rounded-[5px] text-sm">
                        Credit
                      </div>
                      <p className="font-semibold text-transparent w-fit h-[10px] mt-2 rounded-full">
                        GHS 0.10
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-transparent w-fit h-[10px] rounded-full">
                        Wed, 8/30/2023
                      </p>
                      <p className="text-transparent w-fit h-[10px] mt-3 rounded-full text-sm">
                        Successful
                      </p>
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="h-full overflow-y-auto">
                {allTransactions?.length > 0 ? (
                  <>
                    {allTransactions?.map((item: any, index) => (
                      <div key={index} className="border-b border-white pb-3">
                        <div className=" flex items-center justify-evenly gap-2">
                          <div className="flex-[0.1] ">
                            {item?.type?.code === "CREDIT" ? (
                              <div className="bg-[#DCFFF1] rounded-full w-[40px] h-[40px] flex items-center justify-center">
                                <ArrowDownIcon className="text-ks-green-500 w-6 h-6" />
                              </div>
                            ) : (
                              <div className="bg-[#FFECEB] rounded-full w-[40px] h-[40px] flex items-center justify-center">
                                <ArrowUpIcon className="text-primary w-6 h-6" />
                              </div>
                            )}
                          </div>
                          <div className="flex-[0.7]">
                            <div className="text-neutral-300 font-bold text-[16px]">
                              {item?.creator?.name}
                            </div>
                            <div className="text-[14px] leading-[14px]">
                              {item?.description}
                            </div>
                            <div className="text-sm text-[#939291]">
                              {moment(item?.created_at).format(
                                "MMM Do, YYYY, HH:mm"
                              )}
                            </div>
                          </div>

                          <div className="text-neutral-300 text-[14px] flex-[0.2]">
                            {item?.type?.code === "CREDIT" ? "+" : "-"}
                            {item?.amount?.currency}
                            {Intl.NumberFormat("en-EN").format(
                              Number(item?.amount?.value)
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                    >
                      <path
                        d="M73.3337 39.9999V56.6666C73.3337 66.6666 66.667 73.3333 56.667 73.3333H23.3337C13.3337 73.3333 6.66699 66.6666 6.66699 56.6666V39.9999C6.66699 30.9333 12.1337 24.5999 20.6337 23.5332C21.5003 23.3999 22.4003 23.3333 23.3337 23.3333H56.667C57.5337 23.3333 58.367 23.3665 59.167 23.4999C67.767 24.4999 73.3337 30.8666 73.3337 39.9999Z"
                        fill="#A8C1FF"
                      />
                      <path
                        d="M59.1671 23.5001C58.3671 23.3667 57.5338 23.3335 56.6671 23.3335H23.3338C22.4005 23.3335 21.5005 23.4001 20.6338 23.5334C21.1005 22.6001 21.7671 21.7334 22.5671 20.9334L33.4005 10.0667C37.9671 5.53341 45.3671 5.53341 49.9338 10.0667L55.7671 15.9668C57.9005 18.0668 59.0338 20.7334 59.1671 23.5001Z"
                        fill="#5C8BFF"
                      />
                      <path
                        d="M73.3337 41.6667H63.3337C59.667 41.6667 56.667 44.6667 56.667 48.3334C56.667 52.0001 59.667 55.0001 63.3337 55.0001H73.3337"
                        fill="#5C8BFF"
                      />
                    </svg>
                    <div className="header-text-5">
                      NO TRANSACTIONS AVAILABLE!
                    </div>
                    <div className="text-caption mt-5">
                      You have not made any transactions with this wallet.
                    </div>
                    <div className="mt-8 flex flex-col gap-5 items-center w-full">
                      <Button onClick={() => handleTopUp()} width="w-full">
                        <div>Topup wallet</div>
                      </Button>
                      <Button
                        onClick={() => handleBuyFuel()}
                        variant="secondary"
                        width="w-full"
                      >
                        <div>Buy fuel coupon</div>
                      </Button>
                    </div>
                  </div>
                )}

                {limit >= total ? (
                  <></>
                ) : (
                  <div className="pb-14 flex justify-center items-center">
                    <Button
                      onClick={() => onLoadMore()}
                      disabled={limit >= total}
                    >
                      <div className="flex items-center gap-2">
                        View More
                        <ChevronDoubleDownIcon className="h-4 w-4" />
                      </div>
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default WalletHistory;
