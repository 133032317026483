import React from "react";

type Props = {};

const CreatingCoupon = (props: Props) => {
  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center items-center bg-neutral-50">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M28.6499 8.26683C28.6499 8.2835 28.6499 8.2835 28.6499 8.30016C28.2332 8.2835 27.8165 8.26683 27.3665 8.26683H14.5332L16.3165 6.50016C17.9499 4.85016 19.7499 3.3335 21.8332 3.3335C23.9332 3.3335 25.7332 4.85016 27.3665 6.50016L28.2999 7.4335C28.5332 7.65016 28.6499 7.95016 28.6499 8.26683Z"
          fill="#FF6200"
        />
        <path
          d="M34.7334 21.95C35.3834 21.95 35.9001 21.4167 35.9001 20.75V19.2833C35.9001 12.7333 33.9001 10.75 27.3668 10.75H16.8668H12.6334C6.1001 10.75 4.1001 12.75 4.1001 19.2833V20C4.1001 20.6667 4.61676 21.1833 5.26676 21.1833C6.66676 21.1833 7.78343 22.3167 7.78343 23.7C7.78343 25.0833 6.66676 26.2333 5.26676 26.2333C4.61676 26.2333 4.1001 26.75 4.1001 27.4167V28.1333C4.1001 34.6833 6.1001 36.6667 12.6334 36.6667H16.8501H27.3501C33.8834 36.6667 35.8834 34.6667 35.8834 28.1333C35.8834 27.4833 35.3668 26.95 34.7168 26.95C33.3168 26.95 32.2001 25.8333 32.2001 24.45C32.2168 23.0667 33.3334 21.95 34.7334 21.95ZM18.0334 31.4333C18.0334 32.0833 17.5001 32.6167 16.8501 32.6167C16.2001 32.6167 15.6668 32.0833 15.6668 31.4333V26.9667C15.6668 26.3167 16.2001 25.7833 16.8501 25.7833C17.5001 25.7833 18.0334 26.3167 18.0334 26.9667V31.4333ZM18.0334 20.45C18.0334 21.1 17.5001 21.6333 16.8501 21.6333C16.2001 21.6333 15.6668 21.1 15.6668 20.45V15.9833C15.6668 15.3333 16.2001 14.8 16.8501 14.8C17.5001 14.8 18.0334 15.3333 18.0334 15.9833V20.45Z"
          fill="#FF6200"
        />
      </svg>
      <div className="mt-[48px] text-caption">Creating coupon</div>
    </div>
  );
};

export default CreatingCoupon;
