import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

interface navProps {
  activeIndex: number;
}

const CustomNavigation = ({ activeIndex }: navProps) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);

  //get active tab on load
  useEffect(() => {
    setActiveMenu(activeIndex);
  }, [activeIndex]);

  const menuList = [
    {
      id: 1,
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
        >
          <path
            d='M12.5 18V15'
            stroke='#939291'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M10.57 2.81985L3.64002 8.36985C2.86002 8.98985 2.36002 10.2998 2.53002 11.2798L3.86002 19.2398C4.10002 20.6598 5.46002 21.8098 6.90002 21.8098H18.1C19.53 21.8098 20.9 20.6498 21.14 19.2398L22.47 11.2798C22.63 10.2998 22.13 8.98985 21.36 8.36985L14.43 2.82985C13.36 1.96985 11.63 1.96985 10.57 2.81985Z'
            stroke='#939291'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ),
      activeIcon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002Z'
            fill='white'
          />
          <path
            d='M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z'
            fill='#FF6200'
          />
        </svg>
      ),
      name: 'Home',
      route: '/home',
    },
    {
      id: 2,
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M6.73 19.7C7.55 18.82 8.8 18.89 9.52 19.85L10.53 21.2C11.34 22.27 12.65 22.27 13.46 21.2L14.47 19.85C15.19 18.89 16.44 18.82 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.97 4.96 21.59 6.73 19.7Z'
            stroke='#939291'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M8.09607 11H8.10505'
            stroke='#939291'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M10.8984 11H16.3984'
            stroke='#939291'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M8.09607 7H8.10505'
            stroke='#939291'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M10.8984 7H16.3984'
            stroke='#939291'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ),
      activeIcon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.96 4.96 21.59 6.73 19.69L6.74 19.68C7.56 18.81 8.81 18.88 9.52 19.83L10.53 21.18C11.34 22.25 12.65 22.25 13.46 21.18L14.47 19.83C15.19 18.87 16.44 18.8 17.26 19.68C19.04 21.58 20.49 20.95 20.49 18.29V7.04C20.5 3.01 19.56 2 15.78 2ZM7.78 12C7.23 12 6.78 11.55 6.78 11C6.78 10.45 7.23 10 7.78 10C8.33 10 8.78 10.45 8.78 11C8.78 11.55 8.33 12 7.78 12ZM7.78 8C7.23 8 6.78 7.55 6.78 7C6.78 6.45 7.23 6 7.78 6C8.33 6 8.78 6.45 8.78 7C8.78 7.55 8.33 8 7.78 8ZM16.23 11.75H10.73C10.32 11.75 9.98 11.41 9.98 11C9.98 10.59 10.32 10.25 10.73 10.25H16.23C16.64 10.25 16.98 10.59 16.98 11C16.98 11.41 16.64 11.75 16.23 11.75ZM16.23 7.75H10.73C10.32 7.75 9.98 7.41 9.98 7C9.98 6.59 10.32 6.25 10.73 6.25H16.23C16.64 6.25 16.98 6.59 16.98 7C16.98 7.41 16.64 7.75 16.23 7.75Z'
            fill='white'
          />
        </svg>
      ),
      name: 'Coupons',
      route: '/coupons',
    },
    {
      id: 3,
      activeIcon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
        >
          <path
            d='M18 22.75H4C3.59 22.75 3.25 22.41 3.25 22V5C3.25 2.76 4.76 1.25 7 1.25H15C17.24 1.25 18.75 2.76 18.75 5V22C18.75 22.41 18.41 22.75 18 22.75ZM4.75 21.25H17.25V5C17.25 3.59 16.41 2.75 15 2.75H7C5.59 2.75 4.75 3.59 4.75 5V21.25Z'
            fill='#fff'
          />
          <path
            d='M19.5 22.75H2.5C2.09 22.75 1.75 22.41 1.75 22C1.75 21.59 2.09 21.25 2.5 21.25H19.5C19.91 21.25 20.25 21.59 20.25 22C20.25 22.41 19.91 22.75 19.5 22.75Z'
            fill='#fff'
          />
          <path
            d='M13.1102 10.7502H8.88023C7.25023 10.7502 6.24023 9.74023 6.24023 8.11023V6.88023C6.24023 5.25023 7.25023 4.24023 8.88023 4.24023H13.1102C14.7402 4.24023 15.7502 5.25023 15.7502 6.88023V8.11023C15.7502 9.74023 14.7402 10.7502 13.1102 10.7502ZM8.89023 5.75023C8.08023 5.75023 7.75023 6.08023 7.75023 6.89023V8.12022C7.75023 8.93022 8.08023 9.26022 8.89023 9.26022H13.1202C13.9302 9.26022 14.2602 8.93022 14.2602 8.12022V6.89023C14.2602 6.08023 13.9302 5.75023 13.1202 5.75023H8.89023Z'
            fill='#fff'
          />
          <path
            d='M10 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H10C10.41 12.25 10.75 12.59 10.75 13C10.75 13.41 10.41 13.75 10 13.75Z'
            fill='#fff'
          />
          <path
            d='M18 16.7602C17.59 16.7602 17.25 16.4302 17.25 16.0102C17.25 15.6002 17.58 15.2602 18 15.2602L21.75 15.2502V10.4602L20.16 9.67017C19.79 9.48017 19.64 9.03017 19.82 8.66017C20.01 8.29017 20.46 8.14018 20.83 8.32018L22.83 9.32018C23.08 9.45018 23.24 9.71017 23.24 9.99017V15.9902C23.24 16.4002 22.91 16.7402 22.49 16.7402L18 16.7602Z'
            fill='#fff'
          />
        </svg>
      ),
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
        >
          <path
            d='M18 22.75H4C3.59 22.75 3.25 22.41 3.25 22V5C3.25 2.76 4.76 1.25 7 1.25H15C17.24 1.25 18.75 2.76 18.75 5V22C18.75 22.41 18.41 22.75 18 22.75ZM4.75 21.25H17.25V5C17.25 3.59 16.41 2.75 15 2.75H7C5.59 2.75 4.75 3.59 4.75 5V21.25Z'
            fill='#939291'
          />
          <path
            d='M19.5 22.75H2.5C2.09 22.75 1.75 22.41 1.75 22C1.75 21.59 2.09 21.25 2.5 21.25H19.5C19.91 21.25 20.25 21.59 20.25 22C20.25 22.41 19.91 22.75 19.5 22.75Z'
            fill='#939291'
          />
          <path
            d='M13.1102 10.7502H8.88023C7.25023 10.7502 6.24023 9.74023 6.24023 8.11023V6.88023C6.24023 5.25023 7.25023 4.24023 8.88023 4.24023H13.1102C14.7402 4.24023 15.7502 5.25023 15.7502 6.88023V8.11023C15.7502 9.74023 14.7402 10.7502 13.1102 10.7502ZM8.89023 5.75023C8.08023 5.75023 7.75023 6.08023 7.75023 6.89023V8.12022C7.75023 8.93022 8.08023 9.26022 8.89023 9.26022H13.1202C13.9302 9.26022 14.2602 8.93022 14.2602 8.12022V6.89023C14.2602 6.08023 13.9302 5.75023 13.1202 5.75023H8.89023Z'
            fill='#939291'
          />
          <path
            d='M10 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H10C10.41 12.25 10.75 12.59 10.75 13C10.75 13.41 10.41 13.75 10 13.75Z'
            fill='#939291'
          />
          <path
            d='M18 16.7602C17.59 16.7602 17.25 16.4302 17.25 16.0102C17.25 15.6002 17.58 15.2602 18 15.2602L21.75 15.2502V10.4602L20.16 9.67017C19.79 9.48017 19.64 9.03017 19.82 8.66017C20.01 8.29017 20.46 8.14018 20.83 8.32018L22.83 9.32018C23.08 9.45018 23.24 9.71017 23.24 9.99017V15.9902C23.24 16.4002 22.91 16.7402 22.49 16.7402L18 16.7602Z'
            fill='#939291'
          />
        </svg>
      ),
      name: 'Fuel Stations',
      route: '/stations',
    },
    {
      id: 4,
      activeIcon: <Cog6ToothIcon className='w-6 h-6 text-white' />,

      icon: <Cog6ToothIcon className='w-6 h-6 text-[#939291]' />,
      route: '/settings',
      name: 'Settings',
    },
  ];

  const handleNavigation = (selected: any, index: number) => {
    setActiveMenu(index);
    navigate(selected.route);
  };

  return (
    <Fragment>
      <div className='h-[80px] nav-bar relative'>
        {menuList.map((menu: any, i: number) => (
          <button
            key={i}
            className={`${
              activeMenu === i
                ? 'text-white bg-primary h-12 w-12'
                : 'text-neutral-300'
            }  rounded-md flex justify-center items-center mr-3`}
            onClick={() => handleNavigation(menu, i)}
          >
            <div className='flex flex-col justify-center items-center'>
              {activeMenu === i ? (
                <div>{menu.activeIcon}</div>
              ) : (
                <div className='flex flex-col justify-center items-center text-[10px] text-neutral-300'>
                  <div>{menu.icon}</div>
                  <div>{menu.name}</div>
                </div>
              )}
            </div>
          </button>
        ))}
      </div>
    </Fragment>
  );
};

export default CustomNavigation;
