import { useState, useEffect, Fragment } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/solid';
import { Coupon } from '../../types';
import boxImg from '../../images/empty box.png';
import ChooseDrawer from '../home/chooseDrawer';
import CouponCard from '../../components/CouponCard';
import FilterModal from './FilterModal';
import { RouteConstants } from '../../helpers/RouteConstants';
import BaseService from '../../helpers/baseServices';
import Button from '../../components/Button';
import TransferCouponModal from '../home/transferCouponModal';
import moment from 'moment';
import { StorageBox } from '../../helpers/stroage';

type FilterType = {
  f: string;
  o: string;
  p: any;
  c?: string;
};

type OwnershipTypes = 'ANOTHER' | 'SELF' | 'OTHERS';

const Items = ({ title }: { title: any }) => {
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [pageTitle, setPageTitle] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [openBottom, setOpenBottom] = useState(false);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [openTransfer, setOpenTransfer] = useState(false);
  const handleOpenTransfer = () => setOpenTransfer(true);
  const handleCloseTransfer = () => setOpenTransfer(false);

  const [ownershipType, setOwnershipType] = useState<OwnershipTypes | null>(
    null
  );
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const userInfo = StorageBox.retrieveUserData();
  const userId = userInfo?.user?.uid;

  const getAllCoupons = async (lim: number) => {
    const filters: FilterType[] = [];

    setLoading(true);

    if (ownershipType) {
      switch (ownershipType) {
        case 'SELF':
          filters.push({
            f: 'owner.id',
            o: '=',
            p: [`${userId}`],
          });
          break;
        case 'OTHERS':
          filters.push(
            {
              f: 'owner.id',
              o: '!=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '=',
              p: [`${userId}`],
            }
          );
          break;
        case 'ANOTHER':
          filters.push(
            {
              f: 'owner.id',
              o: '=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '!=',
              p: [`${userId}`],
            }
          );
          break;
        default:
      }
    }

    if (fromDate && toDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`,
        ],
      });
    }

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_COUPONS}?sorting=updated_at:desc&limit=${lim}&filters=${JSON.stringify(filters)}`
      );
      setCoupons(data?.payload?.items);
      setTotal(data?.payload?.total);
    } catch (e) {
      //handle error
    } finally {
      setLoading(false);
    }
  };

  const getPaidCoupons = async (lim: number) => {
    const filters: FilterType[] = [];
    if (ownershipType) {
      switch (ownershipType) {
        case 'SELF':
          filters.push({
            f: 'owner.id',
            o: '=',
            p: [`${userId}`],
          });
          break;
        case 'OTHERS':
          filters.push(
            {
              f: 'owner.id',
              o: '!=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '=',
              p: [`${userId}`],
            }
          );
          break;
        case 'ANOTHER':
          filters.push(
            {
              f: 'owner.id',
              o: '=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '!=',
              p: [`${userId}`],
            }
          );
          break;
        default:
      }
    }

    if (fromDate && toDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`,
        ],
      });
    }
    try {
      setLoading(true);
      const response = await BaseService.get_api(
        `${RouteConstants.MY_PAID_COUPONS}?sorting=updated_at:desc&limit=${lim}&filters=${JSON.stringify(filters)}`
      );
      // console.log(response)
      setCoupons(response?.data?.payload?.items);
      setTotal(response?.data?.payload?.total);
      setLoading(false);
    } catch (error) {
      // console.log(error)
      setLoading(false);
    }
  };

  const getUsedCoupons = async (lim: number) => {
    const filters: FilterType[] = [];

    if (fromDate && toDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`,
        ],
      });
    }

    setLoading(true);

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_USED_COUPONS}?sorting=updated_at:desc&limit=${lim}&filters=${JSON.stringify(filters)}`
      );
      setCoupons(data?.payload?.items);
      setTotal(data?.payload?.total);
    } catch (e) {
      //handle error
    } finally {
      setLoading(false);
    }
  };

  const getPendingCoupons = async (lim: number) => {
    const filters: FilterType[] = [];
    if (ownershipType) {
      switch (ownershipType) {
        case 'SELF':
          filters.push({
            f: 'owner.id',
            o: '=',
            p: [`${userId}`],
          });
          break;
        case 'OTHERS':
          filters.push(
            {
              f: 'owner.id',
              o: '!=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '=',
              p: [`${userId}`],
            }
          );
          break;
        case 'ANOTHER':
          filters.push(
            {
              f: 'owner.id',
              o: '=',
              p: [`${userId}`],
              c: 'AND',
            },
            {
              f: 'creator.id',
              o: '!=',
              p: [`${userId}`],
            }
          );
          break;
        default:
      }
    }

    if (fromDate && toDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`,
        ],
      });
    }

    setLoading(true);

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_PENDING_COUPONS}?sorting=updated_at:desc&limit=${lim}&filters=${JSON.stringify(filters)}`
      );
      setCoupons(data?.payload?.items);
      setTotal(data?.payload?.total);
    } catch (e) {
      //handle error
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = () => {
    switch (title) {
      case 'all':
        setPageTitle('All coupons');
        getAllCoupons(limit);
        break;
      case 'pending':
        setPageTitle('Pending payments');
        getPendingCoupons(limit);
        break;
      case 'paid':
        setPageTitle('Paid coupons');
        getPaidCoupons(limit);
        break;
      case 'used':
        setPageTitle('Used coupons');
        getUsedCoupons(limit);
        break;
      default:
        setPageTitle('All coupons');
    }
  };

  //onLoadMore
  const onLoadMore = () => {
    if (limit < total) {
      setLimit(limit + 12);
    }
  };

  useEffect(() => {
    handleTitleChange();
    // eslint-disable-next-line
  }, [limit]);

  return (
    <Fragment>
      <div className="bg-neutral-50 relative h-screen overflow-y-auto">
        <div className="flex justify-between items-center mx-[20px] mt-5">
          <div className="flex-[0.1]">
            <button
              className="h-10 text-neutral-300 flex justify-center items-center"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon className="h-6 w-6 font-bold" />
            </button>
          </div>
          <div className="flex-[0.8] text-center">
            <h4 className="text-header-4">{pageTitle}</h4>
          </div>
          <div className="flex flex-[0.1] w-full justify-end items-end ">
            <button
              className="flex items-center gap-1 "
              onClick={() => {
                setOpenFilter(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M3.59993 1.40039H12.3999C13.1333 1.40039 13.7333 2.00039 13.7333 2.73372V4.20039C13.7333 4.73372 13.3999 5.40039 13.0666 5.73372L10.1999 8.26706C9.79993 8.60039 9.53327 9.26706 9.53327 9.80039V12.6671C9.53327 13.0671 9.2666 13.6004 8.93327 13.8004L7.99994 14.4004C7.13327 14.9337 5.93327 14.3337 5.93327 13.2671V9.73372C5.93327 9.26706 5.6666 8.66706 5.39994 8.33372L2.8666 5.66706C2.53327 5.33372 2.2666 4.73372 2.2666 4.33372V2.80039C2.2666 2.00039 2.8666 1.40039 3.59993 1.40039Z"
                  stroke="#A53D00"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.28667 1.40039L4 6.66706"
                  stroke="#A53D00"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="btn-subtle">Filter</div>
            </button>
          </div>
        </div>

        <div className="mt-5 mx-[20px]">
          <>
            {loading ? (
              <div className="w-full animate-pulse">
                <div className="bg-gray-400 rounded-md mb-2 h-[100px] w-full" />
                <div className="bg-gray-400 rounded-md mb-2 h-[100px] w-full" />
                <div className="bg-gray-400 rounded-md mb-2 h-[100px] w-full" />
              </div>
            ) : (
              <>
                {coupons?.length > 0 ? (
                  <>
                    {coupons.map((item: Coupon, index: number) => (
                      <div key={index} className="mb-5">
                        <CouponCard
                          data={item}
                          textColor={
                            title === 'paid'
                              ? 'ks-green-500'
                              : title === 'pending'
                                ? 'ks-yellow-500'
                                : 'neutral-300'
                          }
                          bgColor={
                            title === 'paid'
                              ? 'ks-green-100'
                              : title === 'pending'
                                ? 'ks-yellow-100'
                                : 'neutral-100'
                          }
                          title={pageTitle}
                          actions={
                            <div className="flex justify-end items-end w-full gap-[20px]">
                              <button
                                onClick={() => navigate(`/coupons/${item?.id}`)}
                                className="btn-small-outline"
                              >
                                View
                              </button>
                              {item?.payment_status?.code === 'COMPLETED' ? (
                                <>
                                  {item?.owner_type?.code === 'SELF' && (
                                    <button
                                      className="btn-small-primary"
                                      onClick={handleOpenTransfer}
                                    >
                                      <div className="text-white flex items-center gap-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M7.06006 5.12012H9.88673V7.95345"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M9.88662 5.12012L6.11328 8.89345"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        <div>Transfer</div>
                                      </div>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.payment_status?.code === 'PENDING' ? (
                                    <button
                                      className="btn-small-primary"
                                      onClick={() => {
                                        if (item?.owner_type?.code === 'SELF') {
                                          let dataForPayment = JSON.stringify({
                                            price: item?.price
                                              .toFixed(2)
                                              .toString(),
                                            type: 'SELF',
                                            fuel_type_code:
                                              item?.fuel_type?.code,
                                            company_code: item?.company?.code,
                                          });
                                          localStorage.setItem(
                                            'dataForPayment',
                                            dataForPayment
                                          );

                                          navigate('/retry-payment');
                                        } else if (
                                          item?.owner_type?.code === 'ANOTHER'
                                        ) {
                                          let dataForPayment = JSON.stringify({
                                            price: item?.price
                                              .toFixed(2)
                                              .toString(),
                                            type: 'ANOTHER',
                                            fuel_type_code:
                                              item?.fuel_type?.code,
                                            company_code: item?.company?.code,
                                            phone: item?.owner?.phone,
                                            first_name: item?.owner?.first_name,
                                            last_name: item?.owner?.last_name,
                                          });
                                          // console.log(JSON.parse(dataForPayment))
                                          localStorage.setItem(
                                            'dataForOtherPayment',
                                            dataForPayment
                                          );

                                          navigate('/retry-payment-others');
                                        }
                                      }}
                                    >
                                      <div className="text-white flex items-center gap-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M6.00016 14.6663H10.0002C13.3335 14.6663 14.6668 13.333 14.6668 9.99967V5.99967C14.6668 2.66634 13.3335 1.33301 10.0002 1.33301H6.00016C2.66683 1.33301 1.3335 2.66634 1.3335 5.99967V9.99967C1.3335 13.333 2.66683 14.6663 6.00016 14.6663Z"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M6 6.33984L8 4.33984L10 6.33984"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M8 4.33984V9.67318"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        <div>Pay</div>
                                      </div>
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          }
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center h-full">
                    <img
                      className="mt-10 lg:w-[300px]"
                      src={boxImg}
                      alt="empty"
                    />
                    <div className="text-center">
                      You have no coupons yet.{' '}
                      <button
                        onClick={() => setOpenBottom(true)}
                        className="text-[#5C8BFF] font-bold"
                      >
                        Click here
                      </button>{' '}
                      to buy coupon.
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>

        {limit >= total ? (
          <></>
        ) : (
          <div className="pb-14 flex justify-center items-center">
            <Button onClick={() => onLoadMore()} disabled={limit >= total}>
              <div className="flex items-center gap-2">
                View More
                <ChevronDoubleDownIcon className="h-4 w-4" />
              </div>
            </Button>
          </div>
        )}

        <br />
        <br />
        <br />
        <br />
      </div>
      <FilterModal
        isOpen={openFilter}
        handleOpenDrawer={() => setOpenFilter(false)}
        type={title}
        handleReset={() => {
          setOwnershipType(null);
          setFromDate(null);
          setToDate(null);
          setOpenFilter(false);
          switch (title) {
            case 'all':
              getAllCoupons(12);
              break;
            case 'pending':
              getPendingCoupons(12);
              break;
            case 'paid':
              getPaidCoupons(12);
              break;
            case 'used':
              getUsedCoupons(12);
              break;
            default:
          }
        }}
        handleOwnership={(value) => {
          setOwnershipType(value);
        }}
        handleFromDate={(date) => {
          setFromDate(date);
        }}
        handleToDate={(date) => setToDate(date)}
        applyFilter={(ownership, from, to) => {
          switch (title) {
            case 'all':
              getAllCoupons(12);
              break;
            case 'pending':
              getPendingCoupons(12);
              break;
            case 'paid':
              getPaidCoupons(12);
              break;
            case 'used':
              getUsedCoupons(12);
              break;
            default:
          }
          setOpenFilter(false);
        }}
      />
      {openBottom && (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => setOpenBottom(false)}
        />
      )}
      {openTransfer ? (
        <TransferCouponModal
          openModal={openTransfer}
          handleOpenModal={handleOpenTransfer}
          handleCloseModal={handleCloseTransfer}
        />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default Items;
