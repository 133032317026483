import BaseService from "../helpers/baseServices";
import { global_variables } from "../helpers/globalVariables";

export const formatPhoneNumber = (str: string) => {
  if (str.startsWith("0")) {
    return "+233" + str.slice(1);
  } else if (str.startsWith("+")) {
    return str;
  } else if (str.startsWith("233")) {
    return "+" + str;
  }

  return str;
};

export const removeBlankSpaces = (inputString: any) => {
  return inputString.replace(/\s+/g, "");
};

export const formatHugeAmount = (number: any) => {
  const SI_SYMBOL = ["", "k", "M", "B", "T"];

  if (Math.abs(number) < 9000) {
    return number.toString();
  }

  // Calculate the magnitude index
  const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);

  // Calculate the formatted number
  const formattedNumber = (number / Math.pow(10, magnitude * 3)).toFixed(1);

  // Add the appropriate abbreviation
  const abbreviation = SI_SYMBOL[magnitude];

  return formattedNumber + abbreviation;
};

export const toTwoDecimalPlaces = (val: any) => {
  return parseFloat(val).toFixed(2);
};

//check transaction status
export const getTransactionStatus = async (transactionId: any) => {
  const maxAttempts = 10;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      // console.log(`Attempt ${attempt}`);

      const response = await BaseService.get_api(
        `${global_variables.get_tranasaction}/${transactionId}`,
      );

      const statusCode = response?.data?.payload?.status?.code;
      if (statusCode === "SUCCESS" || statusCode === "FAILED") {
        return response;
      }

      // Wait for a while before the next attempt
      await new Promise((resolve) => setTimeout(resolve, 15000)); // 15 seconds
    } catch (error) {
      // Handle error if needed
      return error;
    }
  }

  // If not successful after 15 attempts, return an error or handle it accordingly
  return { error: "Max attempts reached, transaction status not updated." };
};

export const getCookie = (cookieName: string) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(";");
  for (let index = 0; index < cookiesArray.length; index++) {
    let cookie = cookiesArray[index];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};
