import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../types";
import {
  UserIcon,
  PhoneIcon,
  EnvelopeIcon,
  CalendarDaysIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import EditPersonal from "./editPersonal";
import ShowToast from "../../components/alerts/all_toasts";
import BaseService from "../../helpers/baseServices";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [userPersonal, setPersonalData] = useState<User | null>();
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = async () => {
    setIsLoading(true);
    try {
      const res = await BaseService.account_ms_get_request(`/v1/profile`);

      setPersonalData(res.data?.payload);
      setIsLoading(false);
      //return res;
    } catch (e) {
      setIsLoading(false);
    }
  };

  const deleteProfilePicture = async () => {
    try {
      await BaseService.account_ms_delete_request(
        `/v1/remove-profile-picture`,
        {}
      );
      ShowToast.success_toast("Profile picture deleted!");
      window.location.reload();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Fragment>
      {step === 1 && (
        <div className="h-screen overflow-hidden bg-neutral-50">
          <div className="h-full mt-5">
            <div className="flex justify-between items-center mx-[20px]">
              <div className="flex-[0.1]">
                <button
                  className="h-10 text-neutral-300 flex justify-center items-center"
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className="h-6 w-6 font-bold" />
                </button>
              </div>
              <div className="flex-[0.8] text-center">
                <h4 className="text-header-4">Personal</h4>
              </div>
              <button
                onClick={() => setStep(2)}
                className="flex gap-1 items-center text-primary flex-[0.1] "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <mask id="path-1-inside-1_1280_5004" fill="white">
                    <path d="M14 14.667H2C1.72667 14.667 1.5 14.4403 1.5 14.167C1.5 13.8937 1.72667 13.667 2 13.667H14C14.2733 13.667 14.5 13.8937 14.5 14.167C14.5 14.4403 14.2733 14.667 14 14.667Z" />
                  </mask>
                  <path
                    d="M14 14.667H2C1.72667 14.667 1.5 14.4403 1.5 14.167C1.5 13.8937 1.72667 13.667 2 13.667H14C14.2733 13.667 14.5 13.8937 14.5 14.167C14.5 14.4403 14.2733 14.667 14 14.667Z"
                    fill="#FF6200"
                  />
                  <path
                    d="M14 13.667H2V15.667H14V13.667ZM2 13.667C2.27895 13.667 2.5 13.888 2.5 14.167H0.5C0.5 14.9926 1.17438 15.667 2 15.667V13.667ZM2.5 14.167C2.5 14.4459 2.27895 14.667 2 14.667V12.667C1.17438 12.667 0.5 13.3414 0.5 14.167H2.5ZM2 14.667H14V12.667H2V14.667ZM14 14.667C13.721 14.667 13.5 14.4459 13.5 14.167H15.5C15.5 13.3414 14.8256 12.667 14 12.667V14.667ZM13.5 14.167C13.5 13.888 13.721 13.667 14 13.667V15.667C14.8256 15.667 15.5 14.9926 15.5 14.167H13.5Z"
                    fill="#FF6200"
                    mask="url(#path-1-inside-1_1280_5004)"
                  />
                  <path
                    d="M12.3263 2.67398L12.3263 2.67399L12.3281 2.67576C12.9397 3.28126 13.1647 3.79913 13.1599 4.27538L13.1599 4.27538V4.28043C13.1599 4.7379 12.935 5.25104 12.3281 5.85175L12.3281 5.85174L12.3249 5.85501L11.6226 6.56311C10.1204 6.08826 8.91491 4.88377 8.43862 3.38214L9.14677 2.67398C9.75747 2.06329 10.2849 1.83494 10.7458 1.83792C11.2085 1.84091 11.73 2.07765 12.3263 2.67398Z"
                    fill="#FF6200"
                    stroke="#FF6200"
                  />
                  <path
                    d="M10.4066 7.68654C10.2132 7.5932 10.0266 7.49987 9.84655 7.3932C9.69989 7.30654 9.55989 7.2132 9.41989 7.1132C9.30655 7.03987 9.17322 6.9332 9.04655 6.82654C9.03322 6.81987 8.98655 6.77987 8.93322 6.72654C8.71322 6.53987 8.46655 6.29987 8.24655 6.0332C8.22655 6.01987 8.19322 5.9732 8.14655 5.9132C8.07989 5.8332 7.96655 5.69987 7.86655 5.54654C7.78655 5.44654 7.69322 5.29987 7.60655 5.1532C7.49989 4.9732 7.40655 4.7932 7.31322 4.60654C7.19085 4.34431 6.84668 4.26641 6.64206 4.47103L2.89322 8.21987C2.80655 8.30654 2.72655 8.4732 2.70655 8.58654L2.34655 11.1399C2.27989 11.5932 2.40655 12.0199 2.68655 12.3065C2.92655 12.5399 3.25989 12.6665 3.61989 12.6665C3.69989 12.6665 3.77989 12.6599 3.85989 12.6465L6.41989 12.2865C6.53989 12.2665 6.70655 12.1865 6.78655 12.0999L10.5416 8.34484C10.7421 8.14432 10.6668 7.79929 10.4066 7.68654Z"
                    fill="#FF6200"
                  />
                </svg>
                <div className="text-sm">Edit</div>
              </button>
            </div>

            {isLoading ? (
              <div className="mt-5">
                <div className="animate-pulse">
                  <div className="flex justify-center items-center">
                    <div className="w-[120px] h-[120px] rounded-[32px] bg-gray-300" />
                  </div>
                </div>

                <div className="mt-5 border-b border-neutral-100 animate-pulse">
                  <div className="flex gap-3 items-center pb-3 mx-[20px]">
                    <div className="w-[20px] h-[20px] bg-gray-300" />
                    <div className="bg-gray-300 w-[80%] h-[20px]" />
                  </div>
                </div>
                <div className="mt-5 border-b border-neutral-100 animate-pulse">
                  <div className="flex gap-3 items-center pb-3 mx-[20px]">
                    <div className="w-[20px] h-[20px] bg-gray-300" />
                    <div className="bg-gray-300 w-[80%] h-[20px]" />
                  </div>
                </div>
                <div className="mt-5 border-b border-neutral-100 animate-pulse">
                  <div className="flex gap-3 items-center pb-3 mx-[20px]">
                    <div className="w-[20px] h-[20px] bg-gray-300" />
                    <div className="bg-gray-300 w-[80%] h-[20px]" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-5">
                <div className="flex justify-center items-center">
                  <div className="w-[120px] h-[120px]">
                    {userPersonal?.image ? (
                      <div className="relative">
                        <img
                          src={userPersonal?.image}
                          className="bg-cover rounded-[32px]"
                          alt=""
                        />
                        <TrashIcon
                          onClick={deleteProfilePicture}
                          className="absolute bottom-0 left-24"
                        />
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className=" w-24 h-24 sm:w-32 sm:h-32 rounded-full text-oya-ghana-green"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <div className="mt-5 border-b border-neutral-100">
                  <div className="flex items-center gap-3 mx-[20px] pb-2">
                    <UserIcon className="h-6 w-6 " />
                    <div>
                      <div className="text-caption">Name</div>
                      <div className="text-neutral-300">
                        {userPersonal?.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 border-b border-neutral-100">
                  <div className="flex items-center gap-3 mx-[20px] pb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_777_4022)">
                        <path
                          d="M7 11C7 12.0609 7.42143 13.0783 8.17157 13.8284C8.92172 14.5786 9.93913 15 11 15C12.0609 15 13.0783 14.5786 13.8284 13.8284C14.5786 13.0783 15 12.0609 15 11C15 9.93913 14.5786 8.92172 13.8284 8.17157C13.0783 7.42143 12.0609 7 11 7C9.93913 7 8.92172 7.42143 8.17157 8.17157C7.42143 8.92172 7 9.93913 7 11Z"
                          fill="#484442"
                          stroke="#484442"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19 3L14 8"
                          stroke="#484442"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15 3H19V7"
                          stroke="#484442"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11 16V22"
                          stroke="#484442"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 19H14"
                          stroke="#484442"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_777_4022">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div>
                      <div className="text-caption">Gender</div>
                      <div className="text-neutral-300">
                        {userPersonal?.gender ? (
                          <div>
                            {userPersonal?.gender === "M" ? "Male" : "Female"}
                          </div>
                        ) : (
                          "Not set"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 border-b border-neutral-100">
                  <div className="flex items-center gap-3 mx-[20px] pb-2">
                    <CalendarDaysIcon className="h-6 w-6 " />
                    <div>
                      <div className="text-caption">Date of birth</div>
                      <div className="text-neutral-300">
                        {userPersonal?.birthdate
                          ? moment(userPersonal?.birthdate).format(
                              "Do MMM YYYY"
                            )
                          : "Not set"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 border-b border-neutral-100">
                  <div className="flex items-center gap-3 mx-[20px] pb-2">
                    <EnvelopeIcon className="h-6 w-6 " />
                    <div>
                      <div className="text-caption">Email address</div>
                      <div className="text-neutral-300">
                        {userPersonal?.email ? userPersonal?.email : "Not set"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 border-b border-neutral-100">
                  <div className="flex items-center gap-3 mx-[20px] pb-2">
                    <PhoneIcon className="h-6 w-6 " />
                    <div>
                      <div className="text-caption">Number</div>
                      <div className="text-neutral-300">
                        {userPersonal?.phone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {step === 2 && <EditPersonal handleStepChange={() => setStep(1)} />}
    </Fragment>
  );
};

export default PersonalInfo;
