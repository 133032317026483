import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

const ChangePin = () => {
  const navigate = useNavigate();
  const [currPin, setCurrPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [currPinError, setCurrPinError] = useState(false);
  const [newPinError, setNewPinError] = useState(false);

  const handlePinChange = () => {
    if (!currPin) {
      setCurrPinError(true);
    } else if (!newPin) {
      setNewPinError(true);
    } else {
      setIsBusy(true);
      // console.log("Busy...")
    }
  };

  return (
    <Fragment>
      <div className="h-screen overflow-hidden bg-[#d7f5f555]">
        <div className="h-full px-[30px] mt-5">
          <div className="flex justify-between items-center">
            <button
              className="h-10 w-10 rounded-full bg-orange-500 text-white flex justify-center items-center"
              onClick={() => navigate("/security")}
            >
              <ArrowLeftIcon className="h-5 w-5" />
            </button>
            <h5>Set New Pin</h5>
            <div></div>
          </div>

          <div className="mt-5">
            <div>
              <small className={`${currPinError ? "text-red-500" : ""}`}>
                Current pin
              </small>
              <input
                className="h-[40px] border-[1px] border-gray-500 w-full bg-transparent rounded-[5px] px-3"
                placeholder="Pin"
                maxLength={4}
                value={currPin}
                onChange={(e) => setCurrPin(e.target.value)}
                disabled={isBusy}
              />
            </div>
            <div className="mt-5">
              <small className={`${newPinError ? "text-red-500" : ""}`}>
                New pin
              </small>
              <input
                className="h-[40px] border-[1px] border-gray-500 w-full bg-transparent rounded-[5px] px-3"
                placeholder="Pin"
                maxLength={4}
                value={newPin}
                onChange={(e) => setNewPin(e.target.value)}
                disabled={isBusy}
              />
            </div>
          </div>

          <div className="mt-[50px] flex justify-center items-center">
            <button
              className="h-[40px] bg-orange-500 text-white flex justify-center items-center rounded-[30px] w-[200px] disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={() => handlePinChange()}
              disabled={isBusy}
            >
              {isBusy ? <Spinner /> : <span>Change Pin</span>}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangePin;
