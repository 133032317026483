export const RouteConstants = {
  // auth routes
  SIGN_IN_ROUTE: '/auth/login',
  SIGN_UP_ROUTE: '/v2/auth/register',
  VERIFY_OTP: '/v2/auth/verify',
  SEND_OTP: '/v2/auth/send-verification',
  REQUEST_RESET_PIN_OTP: '/v2/auth/pin/request-reset-code',
  RESET_PIN: '/v2/auth/pin/reset',
  PROFILE: '/v2/profile',

  //fuel routes
  ALL_STATS: '/me/stats',
  MY_PAID_COUPONS: '/me/paid-coupons',
  MY_PENDING_COUPONS: '/me/unpaid-coupons',
  MY_USED_COUPONS: '/me/used-coupons',
  MY_COUPONS: '/me/coupons',
  MY_COUPON_EVENTS: '/me/coupon-events',
  COUPONS_ROUTE: '/coupons',
  ALL_COMPANIES: '/companies',
  ALL_PROVIDERS: '/providers',
  WALLET_ROUTE: '/me/wallets',
  ALL_TRANSACTIONS: '/transactions',
  FIND_BY_PHONE: '/v2/find-by-phone',
  ALL_FUEL_TYPES: '/fuel-types',
  ALL_FUEL_STATIONS: '/branches',
  ALL_REGIONS: '/regions',
  ALL_SUPPORTED_REGIONS: '/supported-regions'
}
