import { Fragment } from "react";
// import { h5 } from '@material-tailwind/react';
// import logo from "../../images/fuel-white.png"
import FirstStep from "./signUpSteps/firstStep";

const SignUpScreen = () => {
  return (
    <Fragment>
      <div className="h-screen bg-neutral-50 overflow-hidden flex flex-col">
        <div className="w-full px-[20px] mt-[20%]">
          <FirstStep />
        </div>
      </div>
    </Fragment>
  );
};

export default SignUpScreen;
