import { useEffect, useState } from 'react';
import { Spinner } from '@material-tailwind/react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import TransferCouponModal from './transferCouponModal';
import { RouteConstants } from '../../helpers/RouteConstants';
import Button from '../../components/Button';
import BaseService from '../../helpers/baseServices';

const CouponDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedCoupon, setSelectedCoupon] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [couponId, setCouponId] = useState('');

  const [openTransfer, setOpenTransfer] = useState(false);
  const handleOpenTransfer = (id: string) => {
    handleOpenModal();
    setCouponId(id);
  };
  const handleCloseTransfer = () => setOpenTransfer(false);
  const handleOpenModal = () => {
    setOpenTransfer(true);
  };

  useEffect(() => {
    const fetchSingleCoupon = async () => {
      setIsLoading(true);
      try {
        const response = await BaseService.get_api(
          `${RouteConstants.COUPONS_ROUTE}/${id}`
        );
        // console.log(response)
        setSelectedCoupon(response?.data?.payload);

        setIsLoading(false);
      } catch (error) {
        // console.log(error)
      }
    };

    fetchSingleCoupon();
  }, [id]);

  return (
    <>
      <div className="py-5">
        <div className="flex items-center px-5 mt-5">
          <div className="flex-[0.3]">
            <button
              className="text-neutral-300"
              onClick={() => navigate('/home')}
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="flex-[0.7]">
            <h4 className="header-text-4">Coupon details</h4>
          </div>
        </div>

        <div className="mt-5 m-5 p-[20px] rounded-[20px] h-full coupon-details-container">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex justify-between">
                <img
                  src={selectedCoupon?.company?.image_url}
                  alt="comp"
                  className="w-[120px]"
                />

                <div>
                  <div className="flex gap-2">
                    {selectedCoupon?.payment_status ? (
                      <>
                        {selectedCoupon?.payment_status?.code === 'PENDING' ? (
                          <div className="w-fit px-2 py-1 rounded-[3px] bg-yellow-800 mt-1">
                            <p className="text-xs font-medium">
                              {selectedCoupon?.payment_status?.name}
                            </p>
                          </div>
                        ) : selectedCoupon?.payment_status?.code === 'PAID' ||
                          'COMPLETED' ? (
                          <div className="w-fit px-2 py-1 rounded-[3px] bg-[#DCFFF1] mt-1">
                            <p className="text-xs font-medium text-ks-green-500">
                              {selectedCoupon?.payment_status?.name}
                            </p>
                          </div>
                        ) : selectedCoupon?.payment_status?.code ===
                          'FAILED' ? (
                          <div className="w-fit px-2 py-1 rounded-[3px] bg-red-800 mt-1">
                            <p className="text-xs font-medium">
                              {selectedCoupon?.payment_status?.name}
                            </p>
                          </div>
                        ) : (
                          <div className="w-fit px-2 py-1 rounded-[3px] bg-red-800 mt-1">
                            <p className="text-xs font-medium">
                              {selectedCoupon?.payment_status?.name}
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {/* amount and date */}
              <div className="flex flex-col justify-end items-end">
                <div className="text-white text-[24px] font-bold">
                  {selectedCoupon?.currency}{' '}
                  {Intl.NumberFormat('en-EN').format(
                    Number(selectedCoupon?.price)
                  )}
                </div>
                <div className=" text-white text-xs text-right">
                  <div>
                    {moment(selectedCoupon?.created_at).format('MMM Do, YYYY')}
                  </div>
                  <div>
                    {moment(selectedCoupon?.created_at).format('h:mmA')}
                  </div>
                </div>
              </div>

              {/* buyer and owner */}
              <div className="mt-5 flex justify-between">
                <div className="text-white text-left">
                  <p className="text-xs font-normal mb-0">Purchased By</p>
                  <p className="text-[18px] font-semibold mt-0">
                    {selectedCoupon?.creator?.name}
                  </p>
                  <p className="text-[18px] mt-0">
                    {selectedCoupon?.creator?.phone}
                  </p>
                </div>
                <div className="text-white text-right">
                  <p className="text-xs font-normal mb-0">Owner</p>
                  <p className="text-[18px] font-semibold mt-0">
                    {selectedCoupon?.owner?.name}
                  </p>
                  <p className="text-[18px] mt-0">
                    {selectedCoupon?.owner?.phone}
                  </p>
                </div>
              </div>

              {/* amounts */}
              {selectedCoupon?.owner_type?.code === 'SELF' && (
                <div className="mt-5 flex justify-between">
                  <div className="text-white text-left">
                    <p className="text-xs font-normal mb-0">Amount used</p>
                    <p className="text-[18px] font-semibold mt-0">
                      {selectedCoupon?.currency}{' '}
                      {Intl.NumberFormat('en-EN').format(
                        Number(selectedCoupon?.amount_used)
                      )}
                    </p>
                  </div>
                  <div className="text-white text-right">
                    <p className="text-xs font-normal mb-0">Amount left</p>
                    <p className="text-[18px] font-semibold mt-0">
                      {selectedCoupon?.currency}{' '}
                      {Intl.NumberFormat('en-EN').format(
                        Number(selectedCoupon?.amount_left)
                      )}
                    </p>
                  </div>
                </div>
              )}

              {/* fuel type */}
              <div className="mt-5 flex justify-between">
                <div className="text-white text-right">
                  <p className="text-xs font-normal mb-0">Fuel type</p>
                  <p className="text-[18px] font-semibold mt-0">
                    {selectedCoupon?.fuel_type?.name}
                  </p>
                </div>
              </div>

              {selectedCoupon?.owner_type?.code === 'SELF' && (
                <>
                  {selectedCoupon?.payment_status?.code === 'COMPLETED' && (
                    <>
                      <div className="my-5">
                        {/* <div className="flex mr-auto ml-auto w-[250px]" dangerouslySetInnerHTML={{ __html: couplonImg }}></div> */}
                        <img
                          className="flex mr-auto ml-auto w-[200px]"
                          src={`data:image/svg+xml,${encodeURIComponent(
                            selectedCoupon?.coupon_image
                          )}`}
                          alt="QR Code"
                        />
                      </div>

                      <div className="mt-8 flex justify-center">
                        <div className="text-white text-center">
                          <p className="text-xs mt-0">Serial No</p>
                          <div className="text-[18px] font-bold">
                            {selectedCoupon?.reference}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {selectedCoupon?.owner_type?.code === 'SELF' && (
          <>
            {selectedCoupon?.payment_status?.code === 'COMPLETED' && (
              <div className="mt-5 px-5">
                <Button
                  width="w-full"
                  onClick={() => handleOpenTransfer(selectedCoupon?.id)}
                  disabled={isLoading}
                >
                  Transfer Coupon
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      {/* open transfer modal */}
      {openTransfer ? (
        <TransferCouponModal
          openModal={openTransfer}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseTransfer}
          couponId={couponId}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CouponDetails;
