import React from "react";
import processingGif from "../../../images/processingGif.gif";
import Button from "../../../components/Button";

const ProcessingTransaction = ({
  stillProcessing = false,
}: {
  stillProcessing: boolean;
}) => {
  return (
    <React.Fragment>
      {stillProcessing ? (
        <>
          <div className="flex min-h-screen flex-1 flex-col justify-center items-center bg-neutral-50 px-[20px]">
            <div>
              <img className="w-[102px] h-[110px]" src={processingGif} alt="" />
            </div>
            <div className="mt-[48px] text-caption text-center mb-10">
              Payment is still processing. Once transaction is successful, you
              will be notified
            </div>
            <Button
              onClick={() => {
                window.location.replace("/home");
              }}
              width="w-full"
            >
              Dismiss
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="flex min-h-screen flex-1 flex-col justify-center items-center bg-neutral-50">
            <div>
              <img className="w-[102px] h-[110px]" src={processingGif} alt="" />
            </div>
            <div className="mt-[48px] text-caption text-center">
              Payment is being processed. Please wait.
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ProcessingTransaction;
